import React from 'react';
import screen1 from '../../images/download-family-shield.png';
import screen2 from '../../images/install-family-shield.png';
import screen3 from '../../images/signin-family-shield.png';
import {Icon} from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const Download = () => {
    return(
        <div className="downloadPage">
            <div className="center">
                <h2 style={{color : '#666'}}>Download and install the HT Family Shield desktop app on your child&#39;s or family computer</h2>                
            </div>
            <div className="center">
                <a className="button" href="https://ht-vector.com/downloads/family_shield_setup.exe"  title="Download HT Family Shield"><Icon name="windows"/> Download HT Family Shield</a>
            </div>            
            <div className="center">
                <p className="text">If you are on another device right now, use the link below for downloading:</p>
            </div>
            <div className="center">
                <p className="link">ht-vector.com/download-family</p> 
            </div>
            
            <div className="screenshots">
                <div className="step">
                    <img src={screen1} alt="Download HT Family Shield"/>
                    <p className="description">Download HT Family Shield and double-click it.</p>    
                </div>                
                <div className="step">
                    <img src={screen2} alt="Install HT Parental Conrols"/>
                    <p className="description">Launch family_shield_setup.exe and install it on your computer.</p>
                </div>                    
                <div className="step">
                    <img src={screen3} alt="Use HT Family Shield"/>
                    <p className="description">Launch HT Family Shield and sigh in to start.</p>                
                </div>                    
            </div>               
        </div>
    )
}

const DownloadPage = props => {
    return (     
          <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogTitle>
                <div style={{position: "absolute", top: "8px", right: "10px"}}>
                    <IconButton aria-label="close" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Download/>
            </DialogContent>              
          </Dialog>
      )
  }

export default DownloadPage;