import React, { Component } from 'react'
import withUID from './../withUID';
import { versionToNumber } from './../../utils';
import { Tab } from 'semantic-ui-react'
import LastWebsites from './LastWebsites';
import WebChart from './WebChart';
import ClearLogsButton from '../UI/ClearLogsButton';
import firebase from '../../firebase';


const panes = [
    {
      menuItem: 'Chart',
      render: () => <div style={{marginTop : 20}}><WebChart/></div>,
    },
    {
      menuItem: 'History',
      render: () => <div style={{marginTop : 20}}><LastWebsites/></div>,
    },
  ]
  

class Websites extends Component {

  handleClearLogs = () => {   
    const {uid, currentComputer} = this.props;     
    firebase.database().ref(`${uid}/${currentComputer}/reports/websites`).remove();  // clear app report   
    firebase.database().ref(`${uid}/${currentComputer}/reports/lastWebsites`).remove();                 
    const command = {clearWebsites : firebase.database.ServerValue.TIMESTAMP}  
    const commandsRef = firebase.database().ref(`${uid}/${currentComputer}/commands`);             
    commandsRef.update(command);              
  }    
    
  render() {
        const {version} = this.props;
        return (
            <>
                <ClearLogsButton onClick={this.handleClearLogs}/>
                <h2>Websites</h2>	            
                 {(version < 4103) ? <WebChart/> : <Tab menu={{ color : 'blue', secondary: true, pointing: true, attached: false }} panes={panes} />}
            </>
        )
    }
}

export default withUID(Websites);
