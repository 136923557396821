import React from 'react';
import firebase from '../firebase';
import {Icon, Dropdown} from 'semantic-ui-react';
import logo from '../images/logo40.png';
//import '../bootstrap.min.css';
import { mainTitle, helpLink, howToUseLink } from './consts';
import DownloadPage from './UI/download';
import SubscribeReminder from './UI/SubscribeReminder';

const handleSignOut = () => {
    firebase
    .auth()
    .signOut()
    .then(() => {      
      }
    )
  }

const UserPanel = ({user, licenseStatus, daysLeft}) => {  
    const [open, setOpen] = React.useState(false);
    return (    
      <>
      <DownloadPage open={open} handleClose={() => setOpen(false)}/>    
      <nav className="navbar fixed-top flex-md-nowrap" style={{zIndex : 100, padding: "5px 10px"}}>        
        <h2 className="header-logo">
          <img src={logo} alt="logo"/>                                
          <span>{mainTitle}</span>          
        </h2>                
        {//<Computers layout='horizontal'/>
        }
        {licenseStatus && <SubscribeReminder licenseStatus = {licenseStatus} daysLeft={daysLeft}/>}  
        <div className="user-menu" >             
          <Dropdown 
            trigger={ <span><Icon name="user"/><span>{user ? user.email : 'User'}</span></span>}
            direction='left'             
            style={{padding: 10, margin : 0}}       
            >
            <Dropdown.Menu style={{fontSize : '2rem'}}>      
              <Dropdown.Item text='Download desktop app' onClick={() => setOpen(true)}/>              
              <Dropdown.Item><a style={{display: "block", color : "rgba(0,0,0,.95)"}} target="_blank" rel="noopener noreferrer" href={howToUseLink}>How To Use</a></Dropdown.Item>
              <Dropdown.Item><a style={{display: "block", color : "rgba(0,0,0,.95)"}} target="_blank" rel="noopener noreferrer" href={helpLink}>Need Help?</a></Dropdown.Item>              
              <Dropdown.Divider />
              <Dropdown.Item text='Sign Out' onClick={handleSignOut}/>
            </Dropdown.Menu>
          </Dropdown>                 
        </div>          
      </nav>  
                             
      </> 
    );
  }

  export default UserPanel;