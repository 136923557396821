import React  from 'react';
import { Container, Header, Button , Grid, Segment, Icon} from 'semantic-ui-react';

const ALL_USERS = 'All users';

const WizardSelectUser = ({users, selectedUser, onSelectUser, onClickBack, onClickNext}) => {
    const allUsers = users.length > 1 ? [ALL_USERS, ...users] : users;
    const mainText = users.length > 1 ? "Select Your Child's Windows User Account(s)" : "HT Family Shield will operate on this Windows user account";
    return (
        <Container text id='wizard'>
        <Header as='h2'>HT Family Shield: Getting Started - Step 2/4</Header>
        <p className='main-text'>{mainText}</p>    
        <p className="disclaimer">For optimal protection, we recommend that parents have a Windows Administrator account and assign their child a Standard account. This prevents potential security issues. <a href='#'>Learn more</a></p>        
        <Grid style={{marginLeft : 20}}>
            <Grid.Row>
            {allUsers.map((user, index) => (
            <Grid.Column key={index} width={8} style={{maxWidth: 150}}>
                <Segment onClick={() => onSelectUser(user)} className={`user-box ${selectedUser === user ? 'selected' : ''}`}>      
                    {user === ALL_USERS ? (
                        <Icon circular inverted name='users' color='grey' style={{fontSize :'2em'}}/>
                        ) : (
                        <Icon circular inverted name='user outline' color='grey' style={{fontSize :'2em'}}/>
                    )}
                    <h3 style={{marginTop: 20}}>{user}</h3>
                </Segment>
            </Grid.Column>
            )) }
            </Grid.Row>        
        </Grid>    
    
        <p className="disclaimer">* Settings made during the setup wizard are not final and can be easily modified later.</p>
        <div style={{ textAlign: 'right', marginTop: '20px', marginBottom : '20px'}}> {/* Добавлен div для выравнивания кнопки вправо */}
            <Button color='blue' style={{marginRight : 20}} onClick={onClickBack}>Back</Button>           
            <Button color='blue' onClick={onClickNext}>Next</Button>
        </div>         
        </Container>
    )
}

export default WizardSelectUser;