import React, { Component} from 'react';
import firebase from '../../firebase';
import {Checkbox, List, Icon} from 'semantic-ui-react';
import { webCategories } from '../consts';
import withUID from './../withUID';
import ApplyButton from './../UI/ApplyButton';
import { applySettings } from './../../Firebase/applySettings';
import EditList from './../UI/EditList';
import KeyList from './../UI/KeyList';  
import ToolTip from '../UI/ToolTip';

class WebsiteBlocking extends Component {
    state = {
        settingsRef : null,                 
        settings : {},          
        data : null, // updated settings
        editBlackList : false,
        editWhiteList : false,
    }
    
    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                       
        this.setState({settingsRef});
        settingsRef.child('websiteBlocking').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }     

    componentWillUnmount() {
        if (this.state.data) {
            this.handleSaveSettings();
        }
    }    

    handleSaveSettings = () => {                
        this.state.settingsRef.child("websiteBlocking").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		
    }

    onChange = (name, value) => {            
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value},
            editBlackList : false,
            editWhiteList : false,
        }));        
    }

    render() {	
        const {version} = this.props; 
        const {blacklist = [], whitelist = []} = this.state.settings;    
        const blockedCategoriesItems = webCategories.map((item) => (            
            <List.Item key={item.name}><Checkbox className="red" toggle onChange={(e, data) => this.onChange(item.name, data.checked)} label={item.label} checked={this.state.settings[item.name]} /></List.Item>                            
        ));        
        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/>               
				<h2>Website Blocking</h2>	                                                                
                <div className="flex-box web-block">
                {(version < 6101)&&<div style={{minWidth : '250px', maxWidth: '400px', flex : '1 1'}}>
                        <h3>Blocked categories
                            <a href='https://ht-vector.com/ht-family-shield-how-to-use#block-category' rel="noopener noreferrer" target="_blank"><Icon name='question circle' className="help-icon"/></a>
                        </h3>                    
                        <List selection size="big">{blockedCategoriesItems}</List>                                                            
                    </div>}                   
                    <div style={{minWidth : '300px', maxWidth: '450px', flex: '1 1', marginLeft : '15px', marginRight: '15px'}}>
                        <h3>Blocklist<span className="my-badge">websites / keywords</span>
                            <ToolTip 
                                pinned 
                                text={<>List of websites and keywords that will be completely blocked. Add or remove items to customize your restrictions.<br/>
                                <a href='https://ht-vector.com/ht-family-shield-how-to-use#block-websites-header' rel="noopener noreferrer" target="_blank">[Learn more]</a>
                            </>}/>                                                        
                            <Icon name='edit' style={{float : 'right', marginRight: '30px',  color: 'rgb(0,0,0, 0.5)'}} onClick={()=> this.setState({editBlackList : true})}/>
                        </h3>
                        <EditList list={blacklist} name='blacklist' title='Blocklist' key={blacklist} open={this.state.editBlackList} handleClose={() => this.setState({editBlackList : false})} handleSave={this.onChange}/>
                        <KeyList list={blacklist} name='blacklist' onChange={this.onChange} style = {{backgroundColor: '#fff4f4', padding : '10px', marginRight : '15px',  height : '400px', overflowY: 'auto', border : '1px solid #ddd'}}/>                                                             
                        {(version >= 6201)&&<div style={{marginTop : 10}}>
                            <Checkbox toggle label="Force Safe Search on Google" onChange={(e, data) => this.onChange('safeSearch', data.checked)} checked={this.state.settings.safeSearch} />	                                                                                  
                            <ToolTip text="Enable to enforce Google's Safe Search feature, which filters explicit content from google search results."/>
                        </div>  }                         
                    </div>
                    <div style={{minWidth : '300px', maxWidth: '450px', flex: '1 1'}}>
                        <h3>Allowlist<span className="my-badge">websites / keywords</span>
                            <ToolTip 
                                pinned 
                                text={<>List of websites and keywords that are exceptions. Can also be used to exclusively allow only these sites. Configure the mode below.<br/> 
                                <a href='https://ht-vector.com/ht-family-shield-how-to-use#whitelist-header' rel="noopener noreferrer" target="_blank">[Learn more]</a></>}
                            />                                                        
                            <Icon name='edit' style={{float : 'right', marginRight: '30px',  color: 'rgb(0,0,0, 0.5)'}} onClick={()=> this.setState({editWhiteList : true})}/>
                        </h3>
                        <EditList list={whitelist} name='whitelist' title='Allowlist' key={whitelist} open={this.state.editWhiteList} handleClose={() => this.setState({editWhiteList : false})} handleSave={this.onChange}/>                        
                        <KeyList list={whitelist} name='whitelist' onChange={this.onChange} style = {{backgroundColor: '#f8fff6', padding : 10, marginRight : '15px',  height : '400px', overflowY: 'auto', border : '1px solid #ddd'}}/> 
                        <div style={{marginTop : 10}}>
                            <Checkbox toggle label="Allow access only to the websites above" onChange={(e, data) => this.onChange('whiteMode', data.checked)} checked={this.state.settings.whiteMode} />
                            <ToolTip text="Enable to only permit access to websites on the Allowlist. Disable to use the Allowlist as exceptions for blocked websites."/>                            	                                                                                  
                        </div>                        
                    </div>
                </div>           
            </>
        )
    }
}

export default withUID(WebsiteBlocking);
 