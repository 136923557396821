import isEmail from 'validator/lib/isEmail';

const mistapes = [
    '@oulook', 
    '@gamil',
    '@gail',
    '@gnail',
    '@ymail',
    '@gmal',
    '@gmsil',
    '@gmail.om',
]

function isHasMistape(email) {
    const res = mistapes.find((mistape) => email.toUpperCase().indexOf(mistape.toUpperCase()) !== -1);
    return res;
}

export function isValidEmail(email) {
    return isEmail(email) && !isHasMistape(email);
}

export function isPasswordTooShort (password) {
    return password.length < 6;
}

export function isPasswordsMatch (password, passwordConfirm) {
    return password === passwordConfirm;
}

export function isFormEmpty (username, email, password, passwordConfirm) {
    return !username.length || !email.length || !password.length || !passwordConfirm.length;
}