import * as actionTypes from './types';

export const setUser = user => {
    return {
        type : actionTypes.SET_USER,
        payload : {
            currentUser : user 
        }
    }
}

export const clearUser = user => {
    return {
        type : actionTypes.CLEAR_USER,
    }
}


export const setComputers = computers => {
    return {
        type : actionTypes.SET_COMPUTERS,
        payload : {
            computers : computers
        }
    }
}

export const setCurrentComputer = (computer, version) => {
    return {
        type : actionTypes.SET_CURRENT_COMPUTER,
        payload : {
            currentComputer : computer,
            version : version
        }
    }
}

export const setLicenseStatus = (licenseStatus, daysLeft) => {
    return {
        type : actionTypes.SET_LICENSE_STATUS,
        payload : {
            licenseStatus : licenseStatus,
            daysLeft : daysLeft,
        }
    }
}

export const copySettings = (name, settings) => {
    return {
        type : actionTypes.COPY_SETTINGS,
        payload : {
            name, 
            settings,        
        }
    }
}

export const setWeek = (week) => ({
    type: actionTypes.SET_WEEK,
    payload: week
});

export const setDate = (date) => ({
    type: actionTypes.SET_DATE,
    payload: date
});