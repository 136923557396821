const games = [
	"itch.io",
	"minecraft.fandom.com",
	"planetminecraft.com",
	"curseforge.com",
	"aternos.org",
	"y8.com",
	"nexusmods.com",
	"steamcommunity.com",
	"ign.com",
	"xbox.com",
	"store.steampowered.com",
	"minecraftskins.com",
	"crazygames.com",
	"poki.com",
	"lichess.org",
	"chess.com",
	"web.roblox.com",
	"roblox.com",
	"lagged.com",
	"krunker.io",
	"osu.ppy.sh",
	"account.battle.net",
	"agar.io",
	"topgames.com",
	"snake.io",
	"slither.io",
	"friv.com",
	"dndbeyond.com",
	"1v1.lol",
	"hole-io.com",
	"now.gg",
	"silvergames.com",
	"shellshock.io",
	"traderie.com",
	"heroforge.com",
	"kbhgames.com",
	"retrogames.cz"
];

const shopping = [
	"ikea.com",
	"walmart.com",
	"etsy.com",
	"redbubble.com",
	"paypal.com",
	"bestbuy.com",
	"bol.com",
	"shopee.com.my",
	"temu.com",
	"ebay.*",
	"aliexpress.com",
	"amazon.*"
];

const socialMedia = [
  "*snapchat.com",
  "facebook.com",
  "twitter.com",
  "vsco.co",
  "houseparty.com",
  "aminoapps.com",
  "kik.com",
  "yubo.live",
  "skout.com",
  "linkedin.com",
  "wechat.com",
  "line.me",
  "imgur.com",
	"tumblr.com",
	"pinterest.ph",
	"omegle.com",
	"pinterest.com",
	"reddit.com",
	"tiktok.com",
	"vk.com",
	"pinterest.ca",
	"instagram.com",
];

const video = [
	"animefenix.tv",
	"myanimelist.net",
	"ome.tv",
	"theflixer.tv",
	"play.max.com",
	"9anime.to",
	"hdtoday.tv",
	"imdb.com",
	"odysee.com",
	"jkanime.net",
	"aniwatch.to",
	"sflix.to",
	"paramountplus.com",
	"tubitv.com",
	"myflixerz.to",
	"dailymotion.com",
	"jiocinema.com",
	"9animetv.to",
	"movies2watch.tv",
	"crunchyroll.com",
	"primevideo.com",
	"hotstar.com",
	"hulu.com",
	"play.hbomax.com",
	"disneyplus.com",
	"zoro.to",
	"twitch.tv",
	"youtubekids.com",
	"netflix.com",
	"youtube.com"
];

const entertainment = [
	"tapas.io",
	"heywise.com",
	"mangakakalot.com",
	"cbr.com",
	"ranker.com",
	"screenrant.com",
	"mangabuddy.com",
	"buzzfeed.com",
	"furaffinity.net",
	"readcomiconline.li",
	"music.apple.com",
	"kissanime.com.ru",
	"abc.net.au",
	"webnovel.com",
	"quotev.com",
	"kick.com",
	"asurascans.com",
	"music.youtube.com",
	"soundcloud.com",
	"sporcle.com",
	"webtoons.com",
	"open.spotify.com",
	"rotten tomatoes.com",
	"tensportstv.com",
	"deepwoken.fandom.com",
	"adoptme.fandom.com",
	"mcpedl.com",
	"fortnite.gg",
	"trueachievements.com",
	"terraria.fandom.com",
	"game8.co",
	"kimetsu-no-yaiba.fandom.com",
	"chunkbase.com",
	"hoyolab.com",
	"gamefaqs.gamespot.com",
	"genshin-impact.fandom.com",
	"adoptmetradingvalues.com",
	"villains.fandom.com"
];

const productivity = [
	"eventbrite.com",
	"online-stopwatch.com",
	"pastebin.com",
	"tinywow.com",
	"app.slack.com",
	"context.reverso.net",
	"menti.com",
	"vclock.com",
	"timeanddate.com",
	"keep.google.com",
	"padlet.com",
	"calculatorsoup.com",
	"wetransfer.com",
	"microsoft365.com",
	"rapidtables.com",
	"monkeytype.com",
	"icloud.com",
	"view.officeapps.live.com",
	"mega.nz",
	"freeconvert.com",
	"trello.com",
	"quillbot.com",
	"forms.office.com",
	"app.grammarly.com",
	"dropbox.com",
	"onedrive.live.com",
	"notion.so",
	"mail.proton.me",
	"jamboard.google.com",
	"calendar.google.com",
	"drive.google.com",
	"docs.google.com"
	];

const news = [
	"ndtv.com",
	"news.com.au",
	"dailywire.com",
	"theatlantic.com",
	"time.com",
	"edition.cnn.com",
	"ynet.co.il",
	"newsweek.com",
	"theconversation.com",
	"forbes.com",
	"wsj.com",
	"abcnews.go.com",
	"hindustantimes.com",
	"cbsnews.com",
	"cricbuzz.com",
	"nerdwallet.com",
	"npr.org",
	"aljazeera.com",
	"dailymail.co.uk",
	"espncricinfo.com",
	"washingtonpost.com",
	"nbcnews.com",
	"apnews.com",
	"bbc.com",
	"news.yahoo.com",
	"theguardian.com",
	"prothomalo.com",
	"espn.com",
	"cnn.com",
	"reuters.com",
	"nytimes.com",
	"yahoo.com",
	"thehill.com",
	"foxnews.com",
	"news.google.com",
	"bbc.co.uk",
	"msn.com",
];

const communication = [
  "messenger.com",
  "hangouts.google.com",
  "teams.microsoft.com",
  "slack.com",
  "googleduo.com",
  "facetime.apple.com",
  "zalo.me",
  "wire.com",
  "signal.org",
  "threema.ch",
  "blackberrymessenger.com",
  "jitsi.org",
  "tox.chat",
  "riot.im",
  "talky.io",
  "zulip.com",
  "mattermost.com",
  "glip.com",
  "flock.com",
  "highfive.com",
  "uberconference.com",
  "speakap.com",
  "peerio.com",
  "quip.com",
  "dingtalk.com",
  "chanty.com",
  "mail.google.com",
  "meet.google.com",
  "cdn.discordapp.com", 
  "mail.yahoo.com",
  "web.telegram.org",
  "outlook.office365.com",
  "outlook.office.com",
  "outlook.live.com",
  "discord.com",
  "*whatsapp.com"
];

const creative = [
	"powtoon.com",
	"app.clipchamp.com",
	"aggie.io",
	"ko-fi.com",
	"thingiverse.com",
	"pexels.com",
	"musiclab.chromeexperiments.com",
	"shutterstock.com",
	"kapwing.com",
	"unsplash.com",
	"instructables.com",
	"pixlr.com",
	"commons.wikimedia.org",
	"freepik.com",
	"flexclip.com",
	"tabs.ultimate-guitar.com",
	"deviantart.com",
	"soundtrap.com",
	"vimeo.com",
	"pixilart.com",
	"studio.youtube.com",
	"canva.com"
];

const development = [
	"javatpoint.com",
	"stackoverflow.com",
	"sdx.microsoft.com",
	"geeksforgeeks.org",
	"github.com",
	"onlinegdb.com",
	"editor.wix.com",
	"colab.research.google.com",
	"replit.com",
	"wordpress.com",
	"sites.google.com"
];

const education = [
	"brookings.edu",
	"adaptedmind.com",
	"student.uit.edu.vn",
	"monsterbrawl.blooket.com",
	"edclub.com",
	"worldhistory.org",
	"sarthaks.com",
	"artsandculture.google.com",
	"satsuite.collegeboard.org",
	"go.gale.com",
	"onlinelibrary.wiley.com",
	"loc.gov",
	"academic.oup.com",
	"churchofjesuschrist.org",
	"indianexpress.com",
	"cbseacademic.nic.in",
	"morsecode.world",
	"thoughtco.com",
	"vulms.vu.edu.pk",
	"tutorialspoint.com",
	"dictionary.com",
	"coursehero.com",
	"gizmos.explorelearning.com",
	"ted.com",
	"education.nationalgeographic.org",
	"mathsisfun.com",
	"brainpop.com",
	"albert.io",
	"jw.org",
	"geometrydash.io",
	"myapps.classlink.com",
	"mytutor.co.uk",
	"jstor.org",
	"onlinepianist.com",
	"scholar.google.com",
	"shaalaa.com",
	"apcentral.collegeboard.org",
	"wordreference.com",
	"proprofs.com",
	"study.com",
	"history.com",
	"mathsolver.microsoft.com",
	"academia.edu",
	"deepl.com",
	"litcharts.com",
	"chegg.com",
	"teachoo.com",
	"play.blooket.com",
	"vocabulary.com",
	"liveworksheets.com",
	"mathway.com",
	"web.archive.org",
	"sparknotes.com",
	"investopedia.com",
	"merriam-webster.com",
	"masterclass.com",
	"w3schools.com",
	"studocu.com",
	"biblegateway.com",
	"blooket.com",
	"geogebra.org",
	"ncert.nic.in",
	"goodreads.com",
	"room.examgoal.com",
	"zoom.us",
	"student.classdojo.com",
	"clever.com",
	"wikihow.com",
	"fr.wikipedia.org",
	"scribd.com",
	"web.kamihq.com",
	"doubtnut.com",
	"de.wikipedia.org",
	"sketchfab.com",
	"neal.fun",
	"dashboard.blooket.com",
	"vedantu.com",
	"apclassroom.collegeboard.org",
	"coolmathgames.com",
	"brainly.com",
	"preply.com",
	"toppr.com",
	"learncbse.in",
	"learn.microsoft.com",
	"hmhco.com",
	"app.peardeck.com",
	"bard.google.com",
	"sciencedirect.com",
	"britannica.com",
	"wordwall.net",
	"bible.com",
	"typingclub.com",
	"phet.colorado.edu",
	"researchgate.net",
	"*kahoot.it",
	"getepic.com",
	"app.nearpod.com",
	"byjus.com",
	"soraapp.com",
	"vle.learning.moe.edu.sg",
	"learner.outschool.com",
	"education.com",
	"quizizz.com",
	"apps.explorelearning.com",
	"brainly.in",
	"gimkit.com",
	"unacademy.com",
	"edpuzzle.com",
	"math.prodigygame.com",
	"symbolab.com",
	"student.koobits.com",
	"deltamath.com",
	"desmos.com",
	"khanacademy.org",
	"*.zoom.us",
	"abcya.com",
	"udemy.com",
	"mygrace.instructure.com",
	"leetcode.com",
	"smart.newrow.com",
	"login-learn.k12.com",
	"quizlet.com",
	"chesskid.com",
	"bilibili.tv",
	"coursera.org",
	"learning.k12.com",
	"translate.google.com",
	"ixl.com",
	"duolingo.com",
	"archive.org",
	"quora.com",
	"beta.character.ai",
	"chat.openai.com",
	"scratch.mit.edu",
	"pw.live",
	"*wikipedia.org",
	"classroom.google.com",
	"aplus.topper.com"
];

const getHostnameFromRegex = (url) => {
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
  
    return hostname;
  }

  const aliases = ["Entertainment", "Social Media", "Communication", "Games", "Education", "Shopping", "Video", "Productivity", "News", "Creative", "Development"];
  const lists = [entertainment, socialMedia, communication, games, education, shopping, video, productivity, news, creative, development];

  export const webToCategory = (name = '') => {
    let category = 'Other';     
    name = getHostnameFromRegex(name);    

    lists.forEach((list, index) => {
      /*if (list.includes(name)) {
        category = aliases[index]; // Use the corresponding category alias
      } */

      const r = list.find(item => {
        if (item.startsWith('*')) {
          const pattern = item.substring(1);
          return name.indexOf(pattern) > -1;
        } else {
          return name === item;
        }
      });

      if (r) {
        category = aliases[index];
      }
    });

    return category;
  }
  

  