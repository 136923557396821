import React, { useState } from 'react';
import {Button, Dropdown, List} from 'semantic-ui-react';
import AppIcon from '../UI/AppIcon';
import Status from './Status';

 const ApplicationList = (props) => {
    const [newItem, setNewItem] = useState(null);

    const handleAddItem = () => {        
        if (newItem) {
            const list = props.list || [];
            const r = list.find(item => item.name === newItem);
            if (!r) {                
                list.push({name : newItem, status: 'limit'});                                
                props.onChange(props.name, list);
            }            
            setNewItem(null);            
        }
    }

    const handleRemoveItem = (removedItem) => {                
        const list = [...props.list];        
        const index = list.findIndex(item => item.name === removedItem);
        if (index !== -1) {
            list.splice(index, 1);            
            props.onChange(props.name, list);
        }
    }    

    const handleChangeStatus = (name, status) => {
        const updatedItem = {name, status};
        const list = [...props.list].map(item => item.name === name ? updatedItem : item);
        props.onChange(props.name, list);
    }
    	
    const installedApplications = (props.installedApplications || []).map(item => ({
        value : item.name,
        text : item.name,
        content :  <div style={{minHeight : '16px'}}>
                    <AppIcon key={item.name} name={item.name}/>
                </div>
    }));  
    
    const {list = []} = props;    
    const listItems = list.map((item) => {            
        return (
            <List.Item key={item.name}>  
                <div className='blacklist-container'>
                    <div className='blacklist-title'>
                        <div style={{color : 'rgba(0,0,0,.87)'}} ><AppIcon key={item.name} name={item.name}/></div>                    
                    </div>  
                    <div className='blacklist-status'>
                    <Status status={item.status} onChange={(status) => handleChangeStatus(item.name, status)}/>                        
                    </div>
                    <div className='blacklist-remove'>
                        <Button basic circular size='mini' icon='delete' onClick={() => handleRemoveItem(item.name)}/>                                   
                    </div>                                                                                        
                </div>                              
        </List.Item>                            
        )                        
    });     
           
    return (                        
        <>
        <div style={props.style} >
            <List verticalAlign='middle' selection size="large">{listItems}</List>                               
        </div>                        
        <div style={{marginTop : "10px", display : 'flex', flexDirection : 'row', marginRight : '10px'}}>
            <Dropdown search selection clearable defaultOpen={false} allowAdditions style={{flexGrow: 1, marginRight : '10px'}} placeholder="Select application" value={newItem} options={installedApplications} onChange={(e, {value}) => setNewItem(value)}/>
            <Button onClick={handleAddItem}>Add</Button>
        </div>	                                                                                              
        </>                
    )
}   

export default ApplicationList;

