
import React from 'react';
import {mainTitle, subscribeLink} from '../consts';
import {connect} from 'react-redux';

const Status = props => {
	const {status, bypassed, license = 'TRIAL'} = props;    
	let title = `${mainTitle.toUpperCase()} IS PAUSED`;
	let subtitle = `All monitoring and blocking is currently stopped. Click here to start.`;
	let className = "header status-inactive"
	
	if ((status === "active") && (bypassed === '0')) {
		title = `${mainTitle.toUpperCase()} IS ACTIVE`;
		subtitle = `Click here to pause all monitoring and blocking features.`;
		className = "header status-active"
	}

	if ((license !== "TRIAL") && (license !== 'OK')) {
		title = "TRIAL HAS EXPIRED";
		subtitle = <a href={subscribeLink} target="_blank" rel="noopener noreferrer">Buy activation key</a>
		className = "header status-expired"
	}

	return (   
            <div className={className} onClick={props.onClick}>
				<div className="header-title">{title}</div>
				<div className="header-subtitle">{subtitle}</div> 
			</div>)
}

function mapStateToProps(state) {
    return {
      license : state.user.licenseStatus,
    }
  }
  
  export default connect(mapStateToProps)(Status);