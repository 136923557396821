import React, {useState, useEffect }  from 'react';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Portal from '@material-ui/core/Portal';

import firebase from '../../firebase';
import withUID from '../withUID';
import {ages} from './profiles';
import { applySettings } from '../../Firebase/applySettings';
import WizardIntro from './Intro';
import WizardSelectUser from './SelectUser';
import WizardSelectAge from './SelectAge';
import WizardFinish from './Finish';

const ALL_USERS = 'All users';

function AlertSnack(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }  

const WizardContent = ({uid, currentComputer, handleClose, hasSettings, handleDone} ) => {
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]); 
    const [age, setAge] = useState(-1);    
    const [selectedUser, setSelectedUser] = useState(ALL_USERS);

    const handleDoneWizard = (age) => {
        const ref = firebase.database().ref(`${uid}/${currentComputer}/wizard`);
        ref.update({ doneWizard: age });
    };      

    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };

    const handleAgeSelect = (age) => {
        setAge(age);
    };    

    const handleClickNext = () => {                 
        if (page === 4) {
            handleClose();
            handleDoneWizard(age);
            handleDone();
        }
        else if (page === 3) { // save settings             
            // user settings 
            const userSettings = users.reduce((acc, currentUser) => {
                const isCurrentUserSelected = selectedUser === currentUser || selectedUser === ALL_USERS;
                acc[currentUser] = {
                  checkbox1: isCurrentUserSelected,
                  checkbox2: isCurrentUserSelected,
                  checkbox3: isCurrentUserSelected,
                  checkbox4: isCurrentUserSelected,
                  checkbox5: isCurrentUserSelected,
                };
                return acc;
              }, {});
            // rules by age profile
            const ageGroup = ages.find(group => group.age === age);            
            const settings = ageGroup ? ageGroup.profile : {}; 
            settings['userSettings'] = userSettings;

            if (currentComputer) {
                const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);
                settingsRef.set(settings);        
                applySettings(uid, currentComputer);            
            }
        }
        setPage(page + 1);    
    };
    
    const handleClicBack = () => {        
        if (page === 3 && users.length < 2) { // skip page 2 'select user' 
            setPage(1);
        }
        else setPage(page - 1);
    }
    
    useEffect(() => {
        if (!uid || !currentComputer) return;
        const usersRef = firebase.database().ref(`${uid}/${currentComputer}/users`);

        usersRef.once('value', snapshot => {
            const raw = snapshot.val() || {};
            const users = Object.values(raw);                      
            setUsers(users);  
            if (users.length === 1) setSelectedUser(users[0]);

        }).catch(error => {
            console.error(error);
        });
    }, [uid, currentComputer]);

    return (
        <>
        {(page === 1)&&<WizardIntro hasSettings={hasSettings} onTryLater={handleClose} onClickNext={handleClickNext} />}
        {(page === 2)&&<WizardSelectUser selectedUser={selectedUser}users={users} onSelectUser={handleUserSelect} onClickBack={handleClicBack} onClickNext={handleClickNext}/>}        
        {(page === 3)&&<WizardSelectAge age={age} onSelectAge={handleAgeSelect} onClickBack={handleClicBack} onClickNext={handleClickNext} />}        
        {(page === 4)&&<WizardFinish users={users} onClickBack={handleClicBack} onClickNext={handleClickNext} />}                
        </>
        );
}

const Wizard = props => {
    const [showDone, setShowDone] = useState(false);
    const [hasSettings, setHasSettings] = useState(false);    

    const {autorun, uid, currentComputer} = props;

    useEffect(() => { // check if settings exist
        if (!uid || !currentComputer) return;
        const ref = firebase.database().ref(`${uid}/${currentComputer}/settings`);

        ref.once('value', snapshot => {            
            const hasSettings = snapshot.val();
            setHasSettings(hasSettings); // settings empty    

            if (autorun && hasSettings) props.handleClose(); // close Wizard if it autpstarted and the settings exist
            
        }).catch(error => {
            console.error(error);
        });
    }, [uid, currentComputer]);    

    const handleDone = () => {
        setShowDone(true);  }

    return (     
        <>  <Portal>
            <Snackbar             
                anchorOrigin={{ vertical : 'top', horizontal : 'center' }} 
                open={showDone} autoHideDuration={6000} 
                onClose={() => setShowDone(false)}>
                    <AlertSnack onClose={() => setShowDone(false)} severity="info">
                        Your settings have been updated.
                    </AlertSnack>
            </Snackbar>
            </Portal>            
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={false}
                maxWidth="lg"
            >
                <DialogTitle>
                    <div style={{position: "absolute", top: "8px", right: "10px"}}>
                        <IconButton aria-label="close" onClick={props.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <WizardContent {...props} hasSettings={hasSettings} handleDone = {handleDone}/>
                </DialogContent>              
              </Dialog>
          </>          
      )
  }

export default withUID(Wizard);