import React, { Component } from 'react'
import Main from './Main';
import Login from './Auth/login';
import Register from './Auth/register'; 
import ResetPassword from './Auth/reset';
import Spinner from './UI/Spinner';
import firebase from '../firebase';
import {setUser, clearUser} from '../Store/actions';
import {Switch, Route, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';


class Root extends Component {
    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.props.setUser(user);
                this.props.history.push('/')
            } 
            else {                
                if ((this.props.location.pathname !== '/reset') && (this.props.location.pathname !== '/register')) {
                    this.props.history.push('/login')          
                } 
                this.props.clearUser();                               
            }
        })    
    }

    render() {
        return (
            this.props.isLoading 
                ? <Spinner/> 
                : (                
                <Switch>             
                    <Route path="/login" component = {Login} />
                    <Route path="/register" component = {Register} />                        
                    <Route path="/reset" component = {ResetPassword} />                     
                    <Route path="/" component = {Main} />                    
                </Switch>        
                )              
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoading : state.user.isLoading,        
    }
}

export default withRouter(connect(mapStateToProps, {setUser, clearUser})(Root));
