import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import {Table, Checkbox, Icon} from 'semantic-ui-react';
import withUID from './../withUID';
import { applySettings } from './../../Firebase/applySettings';
import ToolTip from '../UI/ToolTip';

const UserSettingsTable = ({ userSettings, handleChange, wizard }) => {
    const settingsList = [
      { name: 'Reports', icon: 'chart line', className : 'report'},
      { name: 'Website Blocking', icon: 'minus circle', className : 'control' },
      { name: 'App Blocking', icon: 'lock', className : 'control'},
      { name: 'Enhanced Security', icon: 'shield', className : 'control' },
      { name: 'Screen Time', icon: 'history', className : 'screen-time' }
    ];
  
    const userHeaderCellStyle = {
      width: '150px', 
      textAlign : 'center'
    };

    const cellStyle = wizard ? {paddingTop : 0, paddingBottom : 0} : {};
  
    return (
      <Table id="user-settings" basic='very' unstackable collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{width : '230px'}}></Table.HeaderCell>
            {userSettings.map((user, index) => (
              <Table.HeaderCell key={index} style={userHeaderCellStyle}>   
                <Icon name="user" color="grey" style={{fontSize : '0.9em'}}/><span style={{marginLeft : 5, fontSize : '1.2em'}}>{user.name}</span>
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {settingsList.map((setting, settingIndex) => (
            <Table.Row key={settingIndex}>
              <Table.Cell style={cellStyle} verticalAlign='middle' className={`feature ${setting.className}`}><Icon name={setting.icon}  style={{marginRight: 10, fontSize: '0.9em'}}  color="grey"/> {setting.name}</Table.Cell>
              {userSettings.map((user, userIndex) => (
                <Table.Cell style={cellStyle} verticalAlign='middle' key={userIndex} className="center custom-toggle">
                  <Checkbox
                    toggle
                    onChange={(e, data) => handleChange(user.name, `checkbox${settingIndex + 1}`, data.checked)}
                    checked={user[`checkbox${settingIndex + 1}`]}
                  />
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };
  
const UserSettings = ({uid, currentComputer, wizard}) => {
    const [settings, setSettings] = useState({});
    const [users, setUsers] = useState([]);

    const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings/userSettings`); 
    
    useEffect(() => {
        if (currentComputer) {    
          // get users        
          const usersRef = firebase.database().ref(`${uid}/${currentComputer}/users`);
          usersRef.once('value',
          // success
          (snapshot) => {
              const raw = snapshot.val() || {};
              const users = Object.values(raw);                
              setUsers(users);
            },
          // error
          (error) => {
              console.log(error);            
          });
            
          settingsRef.on('value',
          // success
          (snapshot) => {        
              const settings = snapshot.val() || {};       
              if (settingsRef) setSettings(settings);
            },
          // error
          (error) => {
              console.log(error);            
          });
  
        }

        return () => {
          settingsRef.off();
        };
    }, [uid, currentComputer]);
        
    const handleSaveSettings = (data) => {           
      settingsRef.update(data);        
      applySettings(uid, currentComputer);
    }

    const handleChange = (username, name, value) => {
        const newSettings = Object.assign({}, settings[username]);
        newSettings[name] = value;

        setSettings(prevSettings => ({
            ...prevSettings, [username]:newSettings})
        );

        const data = {[username]: newSettings};        
        handleSaveSettings(data);       
    };  

    const userSettings = users.map(item => {
        const userSettings = settings[item];            
        return (
            {
                name : item,
                checkbox1 : !userSettings ? true : userSettings.checkbox1 || false,
                checkbox2 : !userSettings ? true : userSettings.checkbox2 || false,
                checkbox3 : !userSettings ? true : userSettings.checkbox3 || false,
                checkbox4 : !userSettings ? true : userSettings.checkbox4 || false,
                checkbox5 : !userSettings ? true : userSettings.checkbox5 || false,                                                                                
            }
            )
    });		

    return (
        <>
            {!wizard&&<h2>User Setting
                <ToolTip
                    pinned
                    text={<>Manage and customize the rules applied to specific computer accounts. Toggle individual features to tailor the protection for each user.<br/>
                    <a href='https://ht-vector.com/ht-family-shield-how-to-use#users-header' rel="noopener noreferrer" target="_blank">[Learn more]</a></>}
                />
            </h2>}	
            <div style={{maxWidth: '1000px', padding: '10px'}}>							                    
                <UserSettingsTable wizard={wizard} userSettings={userSettings} handleChange={handleChange} />            
            </div>                           
        </>
    )
}

export default withUID(UserSettings);
