import React, {useState} from 'react';
import {Dropdown, Message} from 'semantic-ui-react';
import {lastSeen} from '../../utils';
import Confirmation from '../UI/Confirmation';
import EditBox from '../UI/EditBox';
import firebase from '../../firebase';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Portal from '@material-ui/core/Portal';
import {connect} from 'react-redux';
import {copySettings} from '../../Store/actions';
import { applySettings } from '../../Firebase/applySettings';
import AvatarDialog from '../UI/AvatarDialog';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }  

const ComputerMenu = ({name, uid, realOnline, version, settings, alias, avatar, copySettings, settingsFrom}) => {
    const [confirmRemove, setConfirmRemove] = useState(false);
    const [confirmSettings, setConfirmSettings] = useState(false);    
    const [showEditLabel, setShowEditLabel] = useState(false);
    const [showHasCopied, setShowHasCopied] = useState(false);
    const [showHasPasted, setShowHasPasted] = useState(false); 
    const [showAvatarDialog, setShowAvatarDialog] = useState(false); 
    
    const handleRemove = () => {
        setConfirmRemove(false);
        const computersRef = firebase.database().ref(`${uid}/computers`); 
        computersRef.child(name).remove();
        const commandsRef = firebase.database().ref(`${uid}/${name}/commands`);
        commandsRef.update({logOut : firebase.database.ServerValue.TIMESTAMP})
    }    

    const handleSetLabelClick = () => {
        setShowEditLabel(true);
    }    

    const handleSetLabel = (label) => {
        const ref = firebase.database().ref(`${uid}/aliases`); 
        const updatedAlias = `${label};;${avatar || ''}`;
        ref.child(name).set(updatedAlias);
        setShowEditLabel(false);      
    }   

    const handleSetAvatar = (avatar) => {
        const ref = firebase.database().ref(`${uid}/aliases`);
        const updatedAlias = `${alias || ''};;${avatar}`;
        ref.child(name).set(updatedAlias);
        setShowAvatarDialog(false);      
    }            

      const handlePasteSettings = () => {
        setConfirmSettings(false);
        firebase.database().ref(`${uid}/${name}/settings`).update(settings);
        setShowHasPasted(true);
        setTimeout(() => {
            setShowHasPasted(false);
            applySettings(uid, name);
        }, 5000);          
    }

    const handleCopyClick = () => {
        const settingsRef = firebase.database().ref(`${uid}/${name}/settings`);  
        settingsRef.once('value',
        // success
        (snapshot) => {            
            const settings = snapshot.val();
            copySettings(name, settings);
            setShowHasCopied(true);
            setTimeout(() => {
                setShowHasCopied(false);
            }, 5000);            
          },
        // error
        () => {
            
        }); 
    }    

    const menu = <Dropdown.Menu style={{marginTop : 15}}> 
                    <Message content={lastSeen(realOnline)} disabled/>
                    <Dropdown.Item content="Set Label" icon="edit" onClick={handleSetLabelClick}/>                                                
                    <Dropdown.Item content="Set Avatar" icon="user" onClick={() => setShowAvatarDialog(true)}/>
                    <Dropdown.Item content="Copy Settings" icon="upload" onClick={handleCopyClick}/>                            
                    <Dropdown.Item content="Paste Settings" icon="download" disabled={!settingsFrom} onClick={() => setConfirmSettings(true)}/>             
                    <Dropdown.Divider/>
                    <Dropdown.Item content="Remove Computer" icon="remove" onClick={() => setConfirmRemove(true)}/>  
                    {version&&<>
                        <Dropdown.Divider/>
                        <Dropdown.Item content={' version ' + version}/>
                    </>}                           
                </Dropdown.Menu>

return (
    <>    
        <Portal>
        <Snackbar
            style={{ zIndex: 1050 }}              
            anchorOrigin={{ vertical : 'top', horizontal : 'center' }} 
            open={showHasCopied} autoHideDuration={6000} 
            onClose={() => setShowHasCopied(false)}>
                <Alert onClose={() => setShowHasCopied(false)} severity="info">
                    Your settings have been copied.
                </Alert>
        </Snackbar>
        <Snackbar
            style={{ zIndex: 1050 }}              
            anchorOrigin={{ vertical : 'top', horizontal : 'center' }} 
            open={showHasPasted} autoHideDuration={6000} 
            onClose={() => setShowHasPasted(false)}>
                <Alert onClose={() => setShowHasPasted(false)} severity="info">
                    Settings have been pasted. Reload the page.
                </Alert>
        </Snackbar>            
        </Portal>      
        {showEditLabel && <EditBox
          title="Set label"
          caption={`Set the label for ${name}`}
          open={showEditLabel}
          label={alias}
          handleClose={() => setShowEditLabel(false)}
          handleOK={handleSetLabel}
        />}
        {showAvatarDialog && <AvatarDialog
            open={showAvatarDialog}
            handleClose={() => setShowAvatarDialog(false)}
            handleOK={handleSetAvatar}
        />}
        <Confirmation 
            title="Remove computer?"
            text="Are you sure you want to delete this computer from your online dashboard? Please make sure that you have already uninstalled the HT Family Shield app from the computer."
            open={confirmRemove} 
            handleClose={() => setConfirmRemove(false)}
            handleOK={handleRemove}
        />     
        <Confirmation 
            title="Paste settings"
            text={`Overwrite settings with those from ${settingsFrom}?`}
            open={confirmSettings} 
            handleClose={() => setConfirmSettings(false)}
            handleOK={handlePasteSettings}
        /> 
        <Dropdown direction='left' icon={{ color: 'grey', name: 'cog', circular: true}} style={{ marginLeft : 10, color: '#555' }}>
            {menu}
        </Dropdown>                     
     </>
    );

}

function mapStateToProps(state) {
    return {
        settings : state.computers.settings,
        settingsFrom : state.computers.settingsFrom,
        uid : state.user.currentUser.uid,        
    }
}
export default connect(mapStateToProps, {copySettings})(ComputerMenu);