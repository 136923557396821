import React from 'react';
import BlockIcon from '@material-ui/icons/RemoveCircle';
import LimitIcon from '@material-ui/icons/Timelapse';
import AllowIcon from '@material-ui/icons/CheckCircle';
import {StyledMenuItem as MenuItem, StyledSelect as Select} from './StyledComponents.js';
import { Icon } from 'semantic-ui-react';

export const statuses = [
	{
		value : 'allow',
		label : 'Allow',
		className : 'allow'
	}, 
	{
		value : 'limit',
		label : 'Limit',
		className : 'limit'
	},
	{
		value : 'block', 
		label : 'Block',
		className : 'block'
	},
	];

const styles = {
			block : {fontSize : "20px", color : "#dc3545", marginTop: -2, marginRight: 8, float:"left"},
			limit : {fontSize : "20px", color : "#f5c242", marginTop: -2, marginRight: 8, float : "left"},				
			allow : {fontSize : "20px", color : "#28a745", marginTop: -2, marginRight: 8, float : "left"},					
			menuItem: { fontFamily: 'inherit', fontSize : '17px'} // добавляем стиль для MenuItem 
		}

const Status = (props) => {
    const {status = 'allow'} = props;
	return (
		<Select
			value={status}
			onChange={(event) => props.onChange(event.target.value)}
			autoWidth
			disableUnderline
			style={{ fontFamily: 'inherit'}} // добавляем стиль для Select
			>
			<MenuItem style={styles.menuItem} value={'allow'}><Icon name="check circle" style={{marginTop : "1px", fontSize : "18px", height: 'inherit', color : "#28a745", marginRight: 8, float : "left"}}/>{statuses[0].label}</MenuItem>
        	<MenuItem style={styles.menuItem} value={'limit'}><Icon name="time" style={{marginTop : "1px", fontSize : "18px", height: 'inherit', color : "#f5c242", marginRight: 8, float : "left"}}/>{statuses[1].label}</MenuItem>
        	<MenuItem style={styles.menuItem} value={'block'}><Icon name="minus circle" style={{marginTop : "1px", fontSize : "18px", height: 'inherit', color : "#dc3545", marginRight: 8, float : "left"}}/>{statuses[2].label}</MenuItem>
		</Select>
	)
}

export default Status;	