import React, { useState, useEffect } from 'react'
import firebase from '../../firebase';
import {percent, secondsToStringSub} from '../../utils';
import ReportTable from '../UI/ReportTable';
import {Table, Message, Icon, Label} from 'semantic-ui-react';
import moment from 'moment';
import withUID from '../withUID';
import WebIcon from '../UI/WebIcon';
import { Dropdown} from 'semantic-ui-react';
import ToolTip from '../UI/ToolTip';
import DatePicker_2023 from '../UI/DatePicker_2023';
import _ from 'lodash';
import { setWeek, setDate } from '../../Store/actions';
import { connect } from 'react-redux';
import withWebFilter from './withWebFilter';


const WebsiteItem = ({item, totalTime, onAddClick}) => {
	const {name, time} = item; 	
    const link = /^(f|ht)tps?:\/\//i.test(name) ? name : 'http://' + name;   
	const timePercent = percent(time, totalTime);	
	return (
        <>
        <Table.Cell title={name} ><WebIcon name={name}>{name==='Others' ? name : <a href={link} rel="noopener noreferrer" target="_blank">{name}</a>}</WebIcon></Table.Cell>
        <Table.Cell>{secondsToStringSub(time)}<span style={{float: 'right'}}>{timePercent}</span></Table.Cell>
        <Table.Cell className="time-chart"><div className="green chart" style={{width : timePercent}}></div></Table.Cell>       
        <Table.Cell>
            <Dropdown className="actions-dropdown" icon="ellipsis vertical" direction="left" text=''>       
                <Dropdown.Menu>
                    <Dropdown.Item  onClick={() => onAddClick(name, 'block')}>
                        <div><Icon className="block-icon" name="minus circle"/>Block site<ToolTip text='Prevent access to this website.'/></div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onAddClick(name, 'limit')}>
                        <div><Icon className="limit-icon" name="time"/>Limit site<ToolTip text='Apply time restrictions for this website, guided by your settings from the Website Limits tab'/></div>
                    </Dropdown.Item>                    
                </Dropdown.Menu>
            </Dropdown>
        </Table.Cell>    
         </>
	)
}

const collumns = [
    { title : 'Website', className : 'th-name'},
    { title : 'Time', className : 'th-time'},
    { title : '', className : 'time-chart'},
    { title : ' ', className : 'th-dots'},    
];

const WebChart = ({uid, currentComputer, onAddWebsite, date, setDate, week, setWeek}) => {
    const [items, setItems] = useState([]);
    const [isToday, setIsToday] = useState(false);
    const [dates, setDates] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(moment()); 
    
    const startDate = moment(date).startOf('isoWeek');
    const days = !week ? [moment(date).format('YYMMDD')] :  Array.from({ length: 7 }, (_, i) => (moment(startDate).add(i, 'days').format('YYMMDD'))); 
       
    const getDates = (uid, currentComputer) => {                    
        const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/websites/dates`);  
        ref.once('value', // success
            (snapshot) => {                                                
                const datesRaw = Object.keys(snapshot.val() || {}); 
                if (datesRaw.length) {
                    const dates = datesRaw.map(date => moment(date, 'YYMMDD').toDate());                                   
                    const latestDate = moment(new Date(Math.max.apply(null, dates)));    
                    const isToday = moment().diff(latestDate, 'days') === 0;                                        
                    setDates(dates);
                    setIsToday(isToday);
                    if (moment().diff(date, 'days') === 0) setDate(latestDate);
                }
            }, // error
            (error) => {
                console.log(error);            
            });        
    } 

    useEffect(() => {
        if (currentComputer) getDates(uid, currentComputer); 
    }, [uid, currentComputer]);

    useEffect(() => {
        setItems([]);
        if (currentComputer && dates) {
            getItems()
        }
    }, [uid, currentComputer, date, week, lastUpdate])

    const getItems = () => {  
        const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/websites`);                
        days.forEach(date => {        
            ref.child(date).once('value',  
            // success
            (snapshot) => {                
                const items = Object.values(snapshot.val() || {});                                                              
                setItems(prevItems => ({ ...prevItems, [date]: items })); 
            },
            // error
            (error) => {
                console.log(error);            
            });  
        });          
    }        
  
        
    const handleChangeDate = (date) => {                  
        setDate(date);
    }   
        
     const handlePreviousDay = () => {
        const previousDay = moment(date).subtract(week ? 7 : 1, 'day');
        setDate(previousDay);
      };
    
      const handleNextDay = () => {
        const nextDay = moment(date).add(week ? 7 : 1, 'day');
        setDate(nextDay);
      }; 
      
      const groupedData = _.groupBy(_.flatten(Object.values(items)), 'name');
      const itemsWeek = _.map(groupedData, (items, name) => ({
          name,
          time: _.sumBy(items, item => Number(item.time))
        }));
      itemsWeek.sort((a,b) => b.time - a.time);   

    const chart = itemsWeek || [];  

    const totalTime = chart.reduce((total, item) => (total + parseInt(item.time)), 0);  
    const weekStart = moment(date, 'YYMMDD').startOf('isoWeek');
    const weekEnd = moment(date, 'YYMMDD').endOf('isoWeek');  
    const formatedLastUpdate = moment(lastUpdate).format('LTS');      
    const formatedDate = !week ? moment(date, 'YYMMDD').format('MMM DD, YYYY') : `${weekStart.format('MMM DD, YYYY')} - ${weekEnd.format('MMM DD, YYYY')}`;
    return (
        <div style={{padding: 10, minWidth : 450}}>		
            {!isToday&&<Message style={{marginBottom:30}} warning header="Today" content="No activity today"/>}	
           
            <h2 style={{marginBottom : 30, marginTop: 0, marginLeft: 20}}>
           <span className='date-box' style={{width: '100%', textAlign : 'right'}}>                
                <span className={`tab-button day ${!week ? 'active' : ''}`} onClick={() => setWeek(false)}>DAY</span>            
                <span className={`tab-button week ${week ? 'active' : ''}`} onClick={() => setWeek(true)}>WEEK</span>                                                                                                
                <Icon className='date-change' style={{marginLeft: 0}} name='angle left' onClick={handlePreviousDay} />                   
                <Icon className='date-change' name='angle right' onClick={handleNextDay} />        
                <DatePicker_2023 date={moment(date)} includes={dates} onChangeDate={handleChangeDate}/>   
                <span style={{marginLeft : 5, fontSize: '0.9em'}}>{formatedDate}</span>              
                <Label title="refresh" as='a' style={{marginLeft : 20, top : -5}} onClick={() => setLastUpdate(moment())}>
                    <Icon name='refresh' className='date-change' />  
                    {formatedLastUpdate}
                </Label>              
                </span>           
            </h2>	

            <ReportTable unstackable collumns={collumns} list={chart}>
                { (index, item) => <WebsiteItem index={index} item={item} totalTime={totalTime} onAddClick={onAddWebsite}/>}
            </ReportTable>
        </div>                
    )
}


const mapStateToProps = (state) => ({
    week: state.user.week,
    date: state.user.date
  });
  
  const mapDispatchToProps = {
    setWeek,
    setDate
  };

  export default connect(mapStateToProps, mapDispatchToProps)(withUID(withWebFilter(WebChart)));
