import React, {Component} from 'react';
import UserPanel from './UserPanel';
import {connect} from 'react-redux';
import {getStatus} from '../status';
import firebase from '../firebase';
import { setLicenseStatus } from './../Store/actions';
import Content from './Content';
import Sidebar from './Sidebar/Sidebar';

class Main extends Component {
  
loadLicenseStatus = () => {
    getStatus(firebase.auth().currentUser.email)
        .then ((response) => {     
            const {status = 'TRIAL', time = 0} = response;          
            const daysLeft = Math.max(0, 15 - parseInt(time / (3600 * 24)));            
            this.props.setLicenseStatus(status, daysLeft);
        })
        .catch((error) => {
            console.log(error)
        })
}

componentDidMount() {     
    this.loadLicenseStatus(); 
}   

  render() {    
    const {user, licenseStatus, daysLeft} = this.props;
    return (
        <>        
        <UserPanel user={user} licenseStatus={licenseStatus} daysLeft={daysLeft}/>        
        <div className="main-container">
          <Sidebar/>
          {this.props.user&&<Content/>}
        </div>                     
        </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user : state.user.currentUser,
    //currentComputer : state.computers.currentComputer,    
    licenseStatus : state.user.licenseStatus,
    daysLeft : state.user.daysLeft,
  }
}

export default connect(mapStateToProps, {setLicenseStatus})(Main);