import React from 'react';

/* const DisableWrapper = ({ children, isDisabled }) => {
  const disableStyles = {
    pointerEvents: isDisabled ? 'none' : '',
    opacity: isDisabled ? 0.4 : 1,
  };

  return <div style={disableStyles}>{children}</div>;
};

export default DisableWrapper;

*/


const DisableWrapper = ({ children, isDisabled }) => {
  const containerStyles = {
    position: 'relative', // Create a new stacking context
  };

  const disableStyles = {
    pointerEvents: isDisabled ? 'none' : '',
    opacity: isDisabled ? 0.4 : 1,
  };

  const premiumLabelStyles = {
    position: 'absolute', // Position the label above the children
    top: '-3px', // Adjust the distance from the top
    left: '140px', // Move the label to the left
   
    // backgroundColor: '#2185D0',
    borderRadius: '4px', 
   // color: 'white',
   backgroundColor: '#E8F5FF', /* #D5EDFF61*/
   color :  '#2185D0', 
    padding: '1px 5px',
    fontSize: '0.7em', // Make the label slightly smaller
    zIndex: 1, // Put the label above the children
  };

  return (
    <div style={containerStyles}>
      {isDisabled && <div style={premiumLabelStyles}>Premium</div>}
      <div style={disableStyles}>{children}</div>
    </div>
  );
};

export default DisableWrapper;