import React, { Component } from 'react'
import firebase from '../../firebase';
import {Message, Checkbox, Icon} from 'semantic-ui-react';
import withUID from './../withUID';
import ApplyButton from './../UI/ApplyButton';
import { applySettings } from './../../Firebase/applySettings';

class WindowsSecurity extends Component {
    state = {
        settingsRef : null,               
        settings : {}, 
        data : null     
    }

    checkboxes = [
        { name : 'checkbox1', label : 'Restrict access to Windows Control Panel and Windows Settings'},
        { name : 'checkbox2', label : 'Restrict access to Add/Delete Programs Menu'},        
        { name : 'checkbox3', label : 'Restrict access to Task Manager (Ctrl + Alt + Del)'},
        { name : 'checkbox4', label : 'Restrict access to Registry Editor (regedit.exe)'},
        { name : 'checkbox5', label : 'Restrict access to System Configuration Utility (msconfig.exe)'},
        { name : 'checkbox6', label : 'Disable Command Prompt (cmd.exe)'},
        { name : 'checkbox7', label : 'Do not allow user to change Date and Time'},                      
        { name : 'checkbox8', label : 'Disable Microsoft Edge Sidebar (for better content control)'},          
    ]

    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                  
        this.setState({settingsRef});
        settingsRef.child('windowsSecurity').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }     

    componentWillUnmount() {
        if (this.state.data) {
            this.handleSaveSettings();
        }
    }    

    handleSaveSettings = () => {        
        this.state.settingsRef.child("windowsSecurity").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		        
    }

    onChange = (name, value) => {    
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        		
    }
    
    render() {	
        const {version} = this.props;		
        const controls = this.checkboxes.map((checkbox) => {
            return(
                <div key={checkbox.name} style = {{padding : 10}}>
                    <Checkbox toggle onChange={(e, data) => this.onChange(checkbox.name, data.checked)} checked={this.state.settings[checkbox.name]} label={checkbox.label}/>		
                </div>
            )
        });
        // Remove the last checkbox if version < 9701
        if (version < 9701) {
            controls.pop();
        }

        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/> 
				<h2>Enhanced Security
                    <a href='https://ht-vector.com/ht-family-shield-how-to-use#protect-bypassing-header' rel="noopener noreferrer" target="_blank"><Icon name='question circle' className="help-icon"/></a>    
                </h2>	
                <Message info>Enable the Enhanced Security options to provide extra protection for your rules and the filters.</Message>                                
                {controls}   
                <Message info style={{ marginTop: '20px' }}>
                    If your child has admin rights, consider assigning them a <strong>Standard account</strong> for enhanced security.
                    <a href='https://ht-vector.com/parental-controls-help/troubleshooting/change-user-account-to-standard' target='_blank' rel='noopener noreferrer'> Learn how to make this change.</a>
                </Message>                     
            </>
        )
    }
}

export default withUID(WindowsSecurity);
