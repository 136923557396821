import React, {useState} from 'react'
import {percent, secondsToStringSub} from '../../../utils';
import moment from 'moment';
import {Table} from 'semantic-ui-react';
import ComputerTimeByHours from '../ComputerTimeByHours';

const ReportTable = (props) => {
	if (!props.list || props.list.length === 0) return <h3 className="no-data text-center">NO DATA</h3>;	
    const {list = [], collumns = []} = props;
    
	const head = collumns.map((collumn) =>  <Table.HeaderCell key={collumn.title} style={collumn.style} className={collumn.className}>{collumn.title}</Table.HeaderCell>);

	const body = list.map((item, index) => { 

		return(<React.Fragment key = {index}>
        		{props.children(index + 1, item)}        
                </React.Fragment>		
			)
		});

	return (						
			<Table selectable verticalAlign='middle' unstackable={props.unstackable === true} style={{tableLayout : "fixed", width: "100%"}}>
				<Table.Header>
				<Table.Row>
					{head}
				</Table.Row>
				</Table.Header>
				<Table.Body>
					{body}
				</Table.Body>
			</Table>
		);		
}


const ComputerTimeItem = ({index, item, scale, version}) => {
    const [expanded, setExpanded] = useState(index === 1);

	const {date, user = '', activeTime = 0, idleTime = 0, unproductiveTime = 0} = item; 	
    
    const wastedTime = (version >= 9401) || (unproductiveTime > 3600) ? unproductiveTime / 1000 : unproductiveTime;
   // const idleTimeReal = Math.max(0, idleTime - wastedTime);

    const alphaWasted = scale > 0 ? wastedTime/scale : 0;  
    const alphaIdle = scale > 0 ? idleTime/scale : 0;    
    
	const timeWidth = percent(activeTime, scale);
    const idleTimeWidth = percent(idleTime, activeTime);	
    const wastedTimeWidth = percent(wastedTime, activeTime);	

    const rawDate = moment(date, 'YYMMDD');
    const dateStr = rawDate.year() === moment().year() ? rawDate.format("ddd D MMM") : rawDate.format("ddd D MMM YY")
    
	return (
		<>
            <Table.Row  style={{ cursor: 'pointer' }} verticalAlign="middle" key={index} onClick={() => {setExpanded(!expanded)}}>     
            <Table.Cell><span style={{color : '#666', fontSize : '0.7em'}}>{expanded ? '▼' : '►'}</span> {dateStr}</Table.Cell>				
			<Table.Cell title={user}>{user}</Table.Cell>								
			<Table.Cell>{secondsToStringSub(activeTime)}</Table.Cell>
            {(version >= 8104)&&<Table.Cell style={{backgroundColor : `rgba(255, 223, 64, ${alphaWasted})`}}>{secondsToStringSub(wastedTime)}</Table.Cell>}	               
			<Table.Cell style={{backgroundColor : `rgba(204, 204, 204, ${alphaIdle})`}} >{secondsToStringSub(idleTime)}</Table.Cell>	
			<Table.Cell className="time-chart">
                <div style={{ position: 'relative' }}>
                    <div title={`Total time: ${secondsToStringSub(activeTime)}`} className="totaltime chart withShadow" style={{width : timeWidth}}>                     
                    <div title={`Limited time: ${secondsToStringSub(wastedTime)} ${wastedTimeWidth}`} className="wastedtime chart" style={{float: 'right', textAlign : 'center', width : wastedTimeWidth}}>
                        <span className="percentage-label yellow-dark">{wastedTimeWidth}</span>                                                                     
                    </div>                                                                                                   
                    <div title={`Idle time: ${secondsToStringSub(idleTime)} ${idleTimeWidth}`} className="idletime chart" style={{backgroundColor: 'rgba(204, 204, 204, 0.5)', textAlign : 'center', position: 'absolute', top: 0, left: 0 , width : idleTimeWidth}}>
                        <span className="percentage-label" style={{color: '#555'}}>{idleTimeWidth}</span>                             
                    </div>                     
                    </div>
                </div>  			
            </Table.Cell>		            
            </Table.Row>  
            {expanded && <Table.Row key={index + 'hours'}>            
                <td colSpan= {version >= 8104 ? 6 : 5}><ComputerTimeByHours user={user} date={rawDate.format('YYMMDD')}/></td>
            </Table.Row>}
		</>
	)
}

const ComputerTimeTable = ({chart, version}) => {
    const max = chart.reduce((max, item) => Math.max(max, item.activeTime), 3600);    
    const scaleMax = Math.ceil(max / 3600) * 3600;    
    const scaleText = <><div className="scale">{secondsToStringSub(scaleMax / 2)}</div><div className="scale">{secondsToStringSub(scaleMax)}</div></>

    const collumns = [			
        { title : 'Date', style : {width : '140px'}},
        { title : 'User', style : {width : '105px'}},
        { title : 'Total Time', className : 'th-fixed-120'},
        { title : 'Limited Time', className : 'th-fixed-120'},               
        { title : 'Idle Time', className : 'th-fixed-120'},	        
        { title : scaleText, className : 'th-variant time-chart'},
    ];  
    
    if (version < 8104) collumns.splice(3, 1);

    return (
        <ReportTable unstackable collumns={collumns} list={chart}>
        { (index, item) => <ComputerTimeItem index={index} item={item} scale={scaleMax} version={version}/>}
        </ReportTable>
    );
  };
  
  export default ComputerTimeTable;