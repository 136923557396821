import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { versionToNumber } from '../utils';

const Wrapper = WrappedComponent => (props) => {
    const {uid, currentComputer, ...rest} = props;
    return <WrappedComponent key={currentComputer} uid={uid} currentComputer={currentComputer} {...rest}/>          
} 

function mapStateToProps(state) {
    return {
        uid : state.user.currentUser.uid,        
        currentComputer : state.computers.currentComputer,
        version : versionToNumber(state.computers.version || '1.1.1')
    }
}

const withUID = compose(connect(mapStateToProps), Wrapper);
export default withUID;
