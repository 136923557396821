import React, {useState, useEffect} from "react";
import firebase from '../../firebase';
import withUID from '../withUID';
import { takeScreenshot } from "../../Firebase/applySettings";
import { Button, Loader } from "semantic-ui-react";
import moment from "moment";


const Screenshot = ({currentComputer, uid}) => {
    
    const [screenshot, setScreenshot] = useState('');    
    const [time, setTime] = useState(0);    
    const [loading, setLoading] = useState(false);


    const hanldeTakeScreenshot = () => {
        takeScreenshot(uid, currentComputer);
        setLoading(true);
    }    

    useEffect(() => {
       const ref = firebase.database().ref(`${uid}/${currentComputer}/screen`)
            ref.on('value', (snapshot) => {
                setLoading(false);
                const raw = snapshot.val() || {};                              
                setScreenshot(raw['screenshot'] || '');
                setTime(raw['time'] || 0);                
            },    
            (error) => {    
                setLoading(false);
                console.log(error);            
            });

            return () => ref.off();
    }, [uid, currentComputer]);    
    const lastUpdated = time 
        ? <div style={{color : "#555"}}>Last updated: {moment(time).format("D MMM, h:mm:ss a")}</div> 
        : null

    return(
        <div style={{marginRight : 50}}>
            <h3>
                <Button style={{fontSize : '0.95rem'}} onClick={hanldeTakeScreenshot}>TAKE A SCREENSHOT</Button>
                {loading && <Loader active inline style={{marginLeft : 10}}/>}               
                <div className="app-link">{lastUpdated}</div>
            </h3>                            
            <div className="app-chart" style={{height : 'auto', minHeight : '250px', padding : 15}}>                    
                {screenshot&&<img src={`data:image/png;base64,${screenshot}`} style={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)', width: 'auto', height: '100%', }} />}                
            </div>
        </div>        
    )
}

export default withUID(Screenshot);