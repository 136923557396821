
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	button : { 
	  root: {
	    padding: '5px',
	  },
	 },
	select : {
		root : {fontSize: "17px",
				color : "#444",
				minWidth: "75px",
				borderBottom : "0",
				fontFamily : "inherit",
				 },
		select : {
				paddingTop : 3,
		 		paddingBottom : 3,
		 	},
	}, 
	menu : {
		root: {
			paddingTop: 5, 
			paddingBottom: 5, 
			fontSize: "1em",
			color : "#444",
			borderBottom : "0",
			fontFamily : "inherit",
			textAlign: 'left'
			 },							
		},		
};

export const StyledIconButton = withStyles(styles.button)(IconButton);
export const StyledSelect = withStyles(styles.select)(Select);
export const StyledMenuItem = withStyles(styles.menu)(MenuItem);
