import React from 'react';
import {Button, Transition} from 'semantic-ui-react';

const ApplyButton = (props) => {
    const visible = props.enabled ? true : false;
    return(
        
            <div className='save-button'>
                <div>                
                    <Transition visible={visible} animation='browse right' duration={500}>                                
                        <Button onClick={props.onClick}>Apply settings</Button>
                    </Transition>                    
                </div>  
            </div>
        
    )
}

export default ApplyButton;
