import React, {useEffect, useState} from 'react';
import Wizard from './Wizard';
import firebase from '../../firebase';
import withUID from '../withUID';

const AutorunWizard = ({uid, currentComputer}) => {
    const [showWizard, setShowWizard] = useState(false);
    const [wizardSettings, setWizardSettings] = useState({})
  
    const handleCloseWizard = () => {
      setShowWizard(false); 
  
      setWizardSettings(prev => ({...prev, [currentComputer]: false}));
  
      const ref = firebase.database().ref(`${uid}/${currentComputer}/wizard`);
      ref.update({ showWizard: false });
    };
  
    useEffect(() => {      
      if (!uid || !currentComputer) return; 
      if (currentComputer === 'Sample') return;
  
      if (wizardSettings[currentComputer] !== undefined) {
        setShowWizard(wizardSettings[currentComputer]);
        return;
      }      
  
      const ref = firebase.database().ref(`${uid}/${currentComputer}/wizard`);
  
      const getAWizardSettings = (snapshot) => {
        const settings = snapshot.val();        
        const newShowWizard = settings ? settings.showWizard : true;
        setWizardSettings(prev => ({...prev, [currentComputer]: newShowWizard}));
        setShowWizard(newShowWizard);
      };
  
      ref.once('value', getAWizardSettings, (error) => console.log(error));      
  
      setShowWizard(wizardSettings[currentComputer] || false);
  
    }, [currentComputer]);
  
    return  <Wizard autorun open={showWizard} handleClose={handleCloseWizard}/> 
  }

  export default withUID(AutorunWizard);