import React, { Component } from 'react'
import {BrowserRouter as Router} from 'react-router-dom';

import {createStore} from 'redux';
import {Provider} from 'react-redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer from './Store/reducers';
import Root from './componets/Root';

const store= createStore( rootReducer, composeWithDevTools());

export default class App extends Component {    
    render() {    
        return (
            <Provider store={store}>  
                <Router>
                    <Root/>    
                </Router>         
            </Provider>
        )
    }
}

