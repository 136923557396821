import moment from 'moment';
import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import withUID from './../withUID';
import { secondsToString } from '../../utils';
  
const ChartComponent = ({data}) => {
const yAxisLabels = ['60m', '40m', '20m', '0m'];

const overlayLines = Array.from({ length: 3 }, (_, i) => (
    <div key={i} className="line-overlay" style={{top : `${100 * (i / 3)}%`}}></div>
));

const hoursLabels = [0, 6, 12, 18];

const chartBars = data.map((value, index) => {
    const timeHeight = (value / 3600) * 100;    
    return (
        <div key={index} className="chart-bar" style={{margin: 0}}>
            <div
                title={secondsToString(value)}
                className="chart-bar-segment chart-bar-active"
                style={{backgroundColor: '#72a2c6', height: `${Math.max(timeHeight, 0.3)}%` }}
            ></div>
            {hoursLabels.includes(index)&&<div className="chart-bar-label">{`${index.toString().padStart(2, '0')}:00`}</div>}
        </div>
    );
    });

return (
    <div className="chartComputer">
    <div className="chart-y-axis" style={{width: 40}}>
        {yAxisLabels.map((label, index) => (
        <div key={index} className="chart-y-axis-label" style={{position: "absolute", top: `${100 * (index / 3)}%`}}>
            {label}
        </div>
        ))}
    </div>
    {chartBars}
    <div className="chart-overlay" style={{left : 55, width : 410}}>{overlayLines}</div>        
    </div>
);
};

const ComputerTimeByHours = ({uid, currentComputer, date}) => {
    const [computerTime, setComputerTime] = useState([]);
    const [timeRef, setTimeRef] = useState(null);    
    const today = moment(date).format('YYMMDD');    

    const getComputerTime = () => {
        const timeRef = firebase.database().ref(`${uid}/${currentComputer}/reports/timeByHours`);

        setTimeRef(timeRef);
        timeRef.once('value',
            // success
            (snapshot) => {
                const raw = snapshot.val() || {};                                   
                const computerTime = Array.from({ length: 24 }, (_, i) => 
                    {
                        let time = 0;
                        const hour = i < 10 ? '0' + i : i;
                        Object.keys(raw).forEach((user) => {
                            const dayData = (raw[user][today] || {})[hour];
                            if (dayData) time += raw[user][today][hour];
                        });
                        return time;
                    }
                );
                        
                setComputerTime(computerTime);
            },
            // error
            (error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (!currentComputer) return;
        getComputerTime();
    }, [date]);

    return (
        <div>            
            <h3>Hourly Computer Usage</h3>   
            <ChartComponent data={computerTime} />         
        </div>
    )
}

export default withUID(ComputerTimeByHours);