import React, { useState, useEffect } from 'react'
import firebase from '../../firebase';
import { Message, Button } from 'semantic-ui-react';
import { applySettings } from './../../Firebase/applySettings';

const withWebFilter = (WrappedComponent) => {
  return (props) => {
    const [blacklist, setBlacklist] = useState([]);
    const [prevBlacklist, setPrevBlacklist] = useState([]);
    const [showUndo, setShowUndo] = useState(false);
    const [showNotice, setShowNotice] = useState(false);
    const [action, setAction] = useState(null);

    const { uid, currentComputer } = props;

    useEffect(() => {
      const websiteFilterRef = firebase.database().ref(`${uid}/${currentComputer}/settings/websiteFilter/blacklist`);
      getApplicationFilter(websiteFilterRef);            
    }, [uid, currentComputer]);

    const getApplicationFilter = (websiteFilterRef) => {
      websiteFilterRef.once('value',
        // success
        (snapshot) => {
          const fetchedBlacklist = snapshot.val() || [];
          setBlacklist(fetchedBlacklist);
        },
        // error
        (error) => {
          console.error(error);            
        });
    }

    const handleAddWebsite = (name, status) => {
      if (!name) return;

      const existingItem = blacklist.find(item => (item.name === name) && (item.status === status));

      if (existingItem) {
        setShowNotice(true);
        setTimeout(() => setShowNotice(false), 5000);
        return;
      }

      const updatedBlacklist = [...blacklist.filter(item => item.name !== name), { name, status }];

      setBlacklist(updatedBlacklist);
      setPrevBlacklist([...blacklist]);
      setShowUndo(true);
      setAction(status);

      firebase.database().ref(`${uid}/${currentComputer}/settings/websiteFilter/blacklist`).set(updatedBlacklist);
      applySettings(uid, currentComputer);

      setTimeout(() => setShowUndo(false), 5000);
    }

    const handleUndo = () => {
      setBlacklist(prevBlacklist);
      setShowUndo(false);
      firebase.database().ref(`${uid}/${currentComputer}/settings/websiteFilter/blacklist`).set(prevBlacklist);
      applySettings(uid, currentComputer);
    }

    return (
      <>
        <WrappedComponent
          {...props}
          blacklist={blacklist}
          prevBlacklist={prevBlacklist}
          showUndo={showUndo}
          showNotice={showNotice}
          action={action}
          onAddWebsite={handleAddWebsite}
        />
        <div className="messageRoot">
          {showUndo && (
            <Message onDismiss={() => setShowUndo(false)}>
              <Message.Header>Website Limits</Message.Header>
              <Message.Content>
                {action === 'block' && 'The website has been added to the list and will be blocked.'}
                {action === 'limit' && 'The website has been added to the list and will be limited.'}
                {action === 'allow' && 'The website has been added to the list and will be allowed.'}
                <Button onClick={handleUndo} inverted color="red" style={{ marginLeft: 10 }}>Undo</Button>
              </Message.Content>
            </Message>
          )}
          {showNotice && (
            <Message onDismiss={() => setShowNotice(false)} header="Website Limits" content="The website already exists in the list."/>
          )}
        </div>
      </>
    )
  }
}

export default withWebFilter;