import React, {useState, useEffect} from "react";
import firebase from '../../firebase';
import withUID from '../withUID';
import { applySettings } from "../../Firebase/applySettings";
import { Button, Icon } from "semantic-ui-react";

const TaskManagerAlert = ({currentComputer, uid}) => {
    
    const [restrictTaskManager, setRestrictTaskManager] = useState();    

    const handleBlockTaskManager = () => {
        firebase.database()
            .ref(`${uid}/${currentComputer}/settings/windowsSecurity`)
            .update({checkbox3 : true});
        applySettings(uid, currentComputer);

    }    

    useEffect(() => {
       const ref = firebase.database().ref(`${uid}/${currentComputer}/settings/windowsSecurity/checkbox3`)
            ref.on('value', (snapshot) => {
                const raw = snapshot.val() || false;                              
                setRestrictTaskManager(raw);              
            },    
            (error) => {
                console.log(error);            
            });

            return () => ref.off();
    }, [uid, currentComputer]);
    
    if (restrictTaskManager) return null;

    return(
    <div style={{margin: 40, marginLeft: 0}}>
        <div style={{margin : 20, fontSize: "1.2em"}}>
            <Button color='black' basic onClick={handleBlockTaskManager}><Icon name='warning circle' />Click to restrict access to <strong>Task Manager</strong> to protect the program against bypassing.</Button>  
        </div>              
    </div>
    )
}

export default withUID(TaskManagerAlert);