import React from 'react';
import { subscribeLink } from '../consts';

const SubscribeReminder = props => {
  const { licenseStatus, daysLeft } = props;

  if (licenseStatus === 'OK') {
    return null;
  }

  let message = 'Your free trial has expired.';
  if (licenseStatus === 'TRIAL') {
    message = `${daysLeft} days left of free trial.`;
  }

  return (
    <div className="subscribe-reminder">
      {message} <a href={subscribeLink} target="_blank" rel="noopener noreferrer" style={{marginLeft : 20, color: '#4B9AD6', backgroundColor: '#fff', padding: '5px 10px', textDecoration: 'none', borderRadius: '3px'}}>SUBSCRIBE NOW!</a>
    </div>
  )
}

export default SubscribeReminder;