import React, { Component } from 'react'
import {Grid, Form, Segment, Button, Message, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import firebase from '../../firebase';
import Logo from '../UI/Logo';

export default class Login extends Component {
    state = {
        email : '',
        password : '',
        errors : [],
        loading : false, 
        showPassword: false, 
    };

    componentDidMount() {
        const savedEmail = localStorage.getItem('email');
        if (savedEmail) {
            this.setState({ email: savedEmail });
        }
    }      

    displayErrors = errors => errors.map((error, i) => 
        <p key = {i}>{error.message}</p>)
    
    handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            localStorage.setItem('email', value);
        }
        this.setState({ [name]: value });
    }

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
          showPassword: !prevState.showPassword,
        }))
      }
    

    isFormValid = ({email, password}) => email && password;

    handleSubmit = event => {    
        event.preventDefault();
        if (this.isFormValid(this.state)) {
            this.setState({errors : [], loading : true})
            firebase
            .auth()
            .signInWithEmailAndPassword(this.state.email, this.state.password)
            .then (signedUser => {                
                this.setState({loading : false})
            })
            .catch(error => {
                console.log(error)
                if (error.code === "auth/user-not-found") {
                    error.message = "The email you’ve entered doesn’t match any account. Sign up for an account."
                }
                else if (error.code === "auth/wrong-password") {
                    error.message = "Wrong password. Try again or click Forgot password to reset it."
                }
                this.setState  ({errors : this.state.errors.concat(error), loading : false})                
            })
        } else {
            const errors = this.state.errors;
            const error = { message : 'Fill in all fields'};
            this.setState({errors: errors.concat(error)})
        }
    }
    
    render() {
        const { email, password, errors, showPassword, loading} = this.state;
        return (
            <Grid textAlign="center" verticalAlign="middle" className="app">
                <Grid.Column style={{maxWidth : 450}}>                        
                    <Form size="large" onSubmit={this.handleSubmit}>                        
                        <Segment basic style={{padding : '20px'}}>
                            <Logo/>                            
                            <h2 className='login-title'>Sign in to your account</h2>
                            <Form.Input fluid name="email" icon="mail" iconPosition="left" 
                                placeholder="Email Address" onChange={this.handleChange} type="email" value={email}/>
                            <Form.Input fluid name="password" icon="lock" iconPosition="left" 
                                placeholder="Password" onChange={this.handleChange}  type={showPassword ? 'text' : 'password'} value={password}/>
                            <Icon
                                name={showPassword ? 'eye slash outline' : 'eye'}
                                link
                                onClick={this.togglePasswordVisibility}
                                style={{position: 'absolute', color: '#666', right: '2em', marginTop: '-3em', }}
                            />                                 
                            <div style={{display: "flex", justifyContent : "center"}}>
                                <Button disabled={loading} className={loading ? 'loading' : ''} color="blue" fluid size="large" style={{width: "95%", boxShadow: "0 5px 5px rgba(0,0,0,0.25)"}}>LOG IN</Button>                                                                
                            </div>   
                            <div style={{marginTop : "30px"}}>                        
                                Don't have an account? <Link style={{marginLeft: '5px'}} to="/register">Sign up.</Link>
                            </div>                                                                                      
                            <div style={{marginTop: "20px"}}>
                                <Link to="/reset">Forgot password?</Link>                                                                                                    
                            </div>  

                        </Segment>
                    </Form>
                    {errors.length > 0 && 
                        <Message error>
                            <h3>Error</h3>
                            {this.displayErrors(errors)}
                        </Message>
                    }                    
                </Grid.Column>        
            </Grid>
        )
    }
}
