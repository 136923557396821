import React from 'react';
import { Container, Header, Button} from 'semantic-ui-react';
import UserSettings from './UserSettings';

const WizardFinish = ({users, onClickBack, onClickNext}) => {
    return (
    <Container text id='wizard'>
        <Header as='h2'>HT Family Shield: Additional User Settings - Step 4/4</Header>
        <p className='main-text'>Toggle to enable protection features or disable for customized restrictions for your family member(s).</p>        
        <UserSettings users={users}/>    
        <p className="disclaimer">* Settings made during the setup wizard are not final and can be easily modified later.</p>
        <div style={{ textAlign: 'right', marginTop: '20px', marginBottom : '20px'}}> {/* Добавлен div для выравнивания кнопки вправо */}
            <Button color='blue' style={{marginRight : 20}} onClick={onClickBack}>Back</Button>           
            <Button color='blue' onClick={onClickNext}>Finish</Button>
        </div>         
    </Container>
)}

export default WizardFinish;