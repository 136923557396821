import React, { Component } from 'react'
import withUID from './../withUID';
import AppChart from './AppChart';
import LastApps from './LastApps';
import { Tab } from 'semantic-ui-react';
import ClearLogsButton from '../UI/ClearLogsButton';
import firebase from '../../firebase';

const panes = [
    {
      menuItem: 'Chart',
      render: () => <div style={{marginTop : 20}}><AppChart/></div>,
    },
    {
      menuItem: 'History',
      render: () => <div style={{marginTop : 20}}><LastApps/></div>,
    },
  ]
  

class Applications extends Component {

  handleClearLogs = () => {   
    const {uid, currentComputer} = this.props;     
    firebase.database().ref(`${uid}/${currentComputer}/reports/applications`).remove();            
    firebase.database().ref(`${uid}/${currentComputer}/reports/lastApps`).remove();              

    const command = {clearApplications : firebase.database.ServerValue.TIMESTAMP}    
    const commandsRef = firebase.database().ref(`${uid}/${currentComputer}/commands`);                
    commandsRef.update(command);    
  }
  
    
    render() {      
        const {version} = this.props;
        return (  
          <>
            <ClearLogsButton onClick={this.handleClearLogs}/>              				
            <h2>Applications</h2>	                    
            {(version < 4103) ? <AppChart/> : <Tab menu={{ color : 'blue', secondary: true, pointing: true, attached: false }} panes={panes} />}
          </>          
        )
    }
}

export default withUID(Applications);
