
import React from "react";

 const profile_0 = {
    "applicationFilter": {
      "category1": "limit",
      "category2": "block",
      "category3": "block",
      "category4": "limit",
      "category5": "limit",
      "category6": "limit",
      "category7": "block",
      "category8": "block",
      "category9": "block",
      "schedule": [
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111"
      ],
      "timeLimit": 36
    },
    "timeControls": {
      "schedule": [
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111"
      ],
      "timeLimit": 36
    },
    "websiteBlocking": {
      "safeSearch": false
    },
    "websiteFilter": {
      "category1": "block",
      "category10": "block",
      "category11": "limit",
      "category12": "block",
      "category13": "block",
      "category14": "block",
      "category15": "block",
      "category2": "block",
      "category3": "limit",
      "category4": "block",
      "category5": "block",
      "category6": "block",
      "category7": "block",
      "category8": "block",
      "category9": "block",
      "schedule": [
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111",
        "111111000000000000001111"
      ],
      "timeLimit": 24
    }
  }

   const profile_6 = {
    "applicationFilter": {
      "category2": "limit",
      "category3": "limit",
      "category4": "limit",
      "category5": "limit",
      "category6": "limit",
      "category7": "block",
      "category8": "block",
      "category9": "block",
      "schedule": [
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011"
      ],
      "timeLimit": 48
    },
    "timeControls": {
      "schedule": [
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011"
      ],
      "timeLimit": 72
    },
    "websiteFilter": {
      "category1": "limit",
      "category10": "block",
      "category11": "limit",
      "category12": "block",
      "category13": "block",
      "category14": "block",
      "category15": "block",      
      "category2": "limit",
      "category3": "limit",
      "category4": "block",
      "category5": "block",
      "category6": "block",
      "category7": "block",
      "category8": "block",
      "category9": "limit",
      "schedule": [
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011",
        "111111000000000000000011"
      ],
      "timeLimit": 48
    }
  }

   const profile_12 = {
    "applicationFilter": {
      "category4": "limit",
      "category7": "block",
      "category8": "block",
      "category9": "allow",
      "schedule": [
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000"
      ],
      "timeLimit": 48
    },
    "timeControls": {
      "schedule": [
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000"
      ],
      "timeLimit": 120
    },
    "websiteFilter": {
      "category1": "limit",
      "category10": "block",
      "category11": "limit",
      "category13": "block",
      "category14": "block",
      "category2": "limit",
      "category3": "limit",
      "category4": "block",
      "category5": "block",
      "category6": "block",
      "category7": "block",
      "category9": "limit",
      "schedule": [
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000",
        "111111000000000000000000"
      ],
      "timeLimit": 72
    }
  }

const profile_15 = {
    "applicationFilter": {
      "category7": "block",
      "category8": "block",
      "category9": "allow"
    },
    "websiteFilter": {
      "category13": "block",
      "category4": "block",
      "category5": "block",
      "category6": "block",
      "category7": "block"
    }
  }

const profile_noRules = {}

  export const ages = [
    {
      age: -1, // Используем уникальное значение для идентификации этого профиля
      label: "No Rules",
      profile: profile_noRules, // Предполагается, что у вас есть соответствующий профиль
      title: "No Rules: No restrictions imposed.",
      features: [
        { label: (<><b>No restrictions imposed:</b> No specific limits on content or usage.</>), icon: 'unlock' },
        { label: (<><b>Monitoring only:</b> Track computer usage and activities discreetly.</>), icon: 'eye' },
        { label: (<><b>Track computer usage and activities discreetly:</b> Keep an overview without direct intervention.</>), icon: 'search' },
        { label: (<></>), icon: '' },
        { label: (<></>), icon: '' },        
      ]
    },    
    {
      age: 0,
      label: "Age 0-5",
      profile: profile_0,
      title: "Age 0-5: Maximum Protection for Early Years",
      features: [
        { label: (<><b>Content Safety:</b> Block inappropriate content.</>), icon: 'lock' },
        { label: (<><b>Social Interaction Guidance:</b> Block all social media and chat messengers.</>), icon: 'ban' },
        { label: (<><b>Entertainment Moderation:</b> Limit time spent on games and videos.</>), icon: 'gamepad' },
        { label: (<><b>Usage Limits:</b> Screen time limited to 2 hours/day from 6 am to 8 pm.</>), icon: 'time' },
        { label: (<><b>Security Measures:</b> Block unsupported browsers and process managers.</>), icon: 'shield' },
        { label: (<><b>Activity Monitoring:</b> Keep track of computer activities with detailed logs.</>), icon: 'clipboard list' }
      ]
    },
    {
      age: 6,
      label: "Age 6-11",
      profile: profile_6,
      title: "Age 6-11: Focus on Safety and Learning",
      features: [
        { label: (<><b>Content Safety:</b> Block inappropriate content.</>), icon: 'lock' },
        { label: (<><b>Social Interaction Guidance:</b> Limit time on social media, chats, and messengers.</>), icon: 'ban' },
        { label: (<><b>Entertainment Moderation:</b> Restrict time spent on games and videos.</>), icon: 'gamepad' },
        { label: (<><b>Usage Limits:</b> Screen time limited to 6 hours/day from 6 am to 10 pm.</>), icon: 'time' },
        { label: (<><b>Security Measures:</b> Block unsupported browsers and process managers.</>), icon: 'shield' },
        { label: (<><b>Activity Monitoring:</b> Keep track of computer activities with detailed logs.</>), icon: 'clipboard list' }
      ]
    },
    {
      age: 12,
      label: "Age 12-14",
      profile: profile_12,
      title: "Age 12-14: Guided Independence for Pre-Teens",
      features: [
        { label: (<><b>Content Safety:</b> Block inappropriate content.</>), icon: 'lock' },
        { label: (<><b>Social Interaction Guidance:</b> Limit time on social media, chats, and messengers.</>), icon: 'ban' },
        { label: (<><b>Entertainment Moderation:</b> Restrict games and videos.</>), icon: 'gamepad' },
        { label: (<><b>Usage Limits:</b> Screen time limited to 12 hours/day from 6 am to 12 pm.</>), icon: 'time' },
        { label: (<><b>Security Measures:</b> Block unsupported browsers and process managers.</>), icon: 'shield' },
        { label: (<><b>Activity Monitoring:</b> Keep track of computer activities with detailed logs.</>), icon: 'clipboard list' }
      ]
    },
    {
      age: 15,
      label: "Age 15+",
      profile: profile_15,
      title: "Age 15+: Essential Safeguards for Teens",
      features: [
        { label: (<><b>Content Safety:</b> Block inappropriate content.</>), icon: 'lock' },
        { label: (<><b>Security Measures:</b> Block unsupported browsers and process managers.</>), icon: 'shield' },
        { label: (<><b>Activity Monitoring:</b> Keep track of computer activities with detailed logs.</>), icon: 'clipboard list' },
        { label: (<></>), icon: '' },
        { label: (<></>), icon: '' },
        { label: (<></>), icon: '' }                          
      ]
    }
  ];
  
  
  