import React, { Component } from 'react'
import {Grid, Form, Segment, Button, Message} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import firebase from '../../firebase';
import Logo from '../UI/Logo';
import { isValidEmail } from './validation';
import { homepageLink } from '../consts';

export default class ResetPassword extends Component {
    state = {
        email : '',        
        errors : [],
        loading : false, 
        reset : false,
    };

    displayErrors = errors => errors.map((error, i) => 
        <p key = {i}>{error.message}</p>)
    
    handleChange = (event) => {
        this.setState({[event.target.name] : event.target.value})
    }

    handleSubmit = event => {            
        event.preventDefault();
        const email = this.state.email;  
        let errors = [];
        let error;    

        if (!isValidEmail(email)) {
            error = { message : 'Email is invalid'};
            this.setState({errors: errors.concat(error)})
            return false;
        }    

        const actionCodeSettings = {
            url: homepageLink            
          };            
        firebase
        .auth()        
        .sendPasswordResetEmail(email, actionCodeSettings)
        .then (() => {                
            this.setState({reset : true})
        })
        .catch(error => {
            console.log(error);
            this.setState({errors : this.state.errors.concat(error), loading : false})                
        })
    }
    
    render() {
        const { email, errors, loading} = this.state;        
        if (this.state.reset) return (
            <Grid style={{backgroundColor : '#666'}} textAlign="center" verticalAlign="middle" className="app">
                <Grid.Column style={{maxWidth : 500}}>                                                   
                        <Segment stacked style={{padding : '20px'}}>
                            <Logo/>
                            <h2 className="login-title">Reset Your Password</h2>
                            <h4 className='login-subtitle'>We have sent your password reset link to <b>{email}</b>. If you don't see the email, please check your spam folder.</h4>                        
                            <Message 
                                style={{textAlign : 'left'}} 
                                warning
                                header='Attention!'
                                content="After changing your password, you need to re-login to the HT Family Shield app on your child's computer with your new password. Open the app using the desktop icon or from the installation folder, and enter your new password."
                            />                                
                            <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between", alignItems : "center", marginTop : "20px"}}>
                                <Link to="/login">Back to login</Link>  
                            </div>   
                            
                        </Segment>            
                </Grid.Column>        
            </Grid>        
        );

        return (
            <Grid style={{backgroundColor : '#666'}} textAlign="center" verticalAlign="middle" className="app">
                <Grid.Column style={{maxWidth : 500}}>                                
                    <Form size="large" onSubmit={this.handleSubmit}>                        
                        <Segment stacked style={{padding : '20px'}}>
                            <Logo/>
                            <h2 className="login-title">Forgot your password?</h2>
                            <h4 className='login-subtitle'>Enter your email, and we’ll send you instructions on how to reset your password.</h4>
                            <Form.Input fluid name="email" icon="mail" iconPosition="left" 
                                placeholder="Email Address" onChange={this.handleChange} type="email" value={email}/>
                            <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between", alignItems : "center", marginTop : "20px"}}>
                                <Button disabled={loading} className={loading ? 'loading' : ''} color="blue" fluid size="large" style={{width: 170}}>Send</Button>                               
                            </div>                                
                        </Segment>
                    </Form>
                    {errors.length > 0 && 
                        <Message error>
                            <h3>Error</h3>
                            {this.displayErrors(errors)}
                        </Message>
                    }                    
                </Grid.Column>        
            </Grid>
        )
    }
}
