import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import withUID from '../withUID';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { percent,  secondsToString, versionToNumber } from '../../utils';

    const formatTime = (timeInSeconds) => {      
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        
        if (minutes < 60) {
            return `${minutes}m ${seconds}s`;
        }
        
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        return remainingMinutes > 0 ? `${hours}h ${remainingMinutes}m` : `${hours}h`;
    };
      
    const ChartComponent = ({data, date, week, version}) => {    
    const startDate = week ? moment(date).startOf('isoWeek') : moment(date).subtract(6, 'days');
    const days = Array.from({ length: 7 }, (_, i) => moment(startDate).add(i, 'days'));
    
    const chartData = days.map((day) => {
        const dayKey = day.format('YYMMDD');    
        let activeTime = 0;
        let idleTime = 0;
        let unproductiveTime = 0;

        Object.keys(data).forEach((user) => {
            const dayData = data[user][dayKey];
            if (dayData) {
                activeTime += dayData.activeTime || 0;
                idleTime += dayData.idleTime || 0;
                unproductiveTime += dayData.unproductiveTime || 0;                
            }
        });
    
        return { dayKey, activeTime, idleTime, unproductiveTime };
    });
    
    let maxTotalTime = Math.max(
        ...chartData.map(({activeTime}) => activeTime)
    );

    maxTotalTime =  (maxTotalTime < 3600) ? Math.ceil(maxTotalTime / 240) * 240 : Math.ceil(maxTotalTime / 3600) * 3600 // round
    
    const yAxisLabels = [0.25, 0.5, 0.75, 1].map((percentage) => {
        const timeInSeconds = Math.floor(maxTotalTime * percentage);
        return formatTime(timeInSeconds);
    }).reverse();

    const overlayLines = Array.from({ length: 5 }, (_, i) => (
        <div key={i} className="line-overlay" style={{top : `${i * 25}%`}}></div>
    ));
    
    const chartBars = chartData.map((item) => {
        const {dayKey, activeTime, idleTime} = item;
        
        const unproductiveTime = (version >= 9401) || (item.unproductiveTime > 3600) ? item.unproductiveTime / 1000 : item.unproductiveTime;
        const activeHeight = ((activeTime - unproductiveTime) / maxTotalTime) * 100;
   
        const idleHeight = (idleTime / maxTotalTime) * 100;          
        const wastedHeight = (unproductiveTime / maxTotalTime) * 100;    
        
        const idlePercent = percent(idleTime, activeTime);
        const unproductivePercent = percent(unproductiveTime, activeTime);

        return (
            <div key={dayKey} className="chart-bar">
                <div
                    className="chart-bar-segment chart-bar-active"
                    title={`Total time: ${secondsToString(activeTime)}`}
                    style={{ height: `${Math.max(activeHeight, 0.3)}%` }}
                ></div>
                <div
                    className="chart-bar-segment chart-bar-wasted"
                    title={`Limited time: ${secondsToString(unproductiveTime)} ${unproductivePercent}`}
                    style={{ height: `${Math.max(wastedHeight, 0.01)}%` }}
                ></div>
                <div
                    className="chart-bar-segment chart-bar-idle"
                    title={`Idle time: ${secondsToString(idleTime)} ${idlePercent}`}
                    style={{ height: `${Math.max(idleHeight, 0.01)}%`, backgroundColor: 'rgba(204, 204, 204, 0.5)', position: 'absolute', bottom: 0 }}
                ></div>                
                <div className="chart-bar-label">{moment(dayKey, 'YYMMDD').format('DD MMM')}</div>
            </div>
        );
        });
    
    return (
        <div className="chartComputer">
        <div className="chart-y-axis">
            {yAxisLabels.map((label, index) => (
            <div key={index} className="chart-y-axis-label" style={{position: "absolute", top: `${index * 25}%`}}>
                {label}
            </div>
            ))}
        </div>
        {chartBars}      
        <div className="chart-overlay">{overlayLines}</div>         
        </div>
    );
    };

      const DashboardComputerTime_v2 = ({uid, version, currentComputer, date, week}) => {
        const [computerTime, setComputerTime] = useState([]);
            
        useEffect(() => {
            if (!currentComputer) return;
            const timeRef = firebase.database().ref(`${uid}/${currentComputer}/reports/timeByDays`);                
            timeRef.once('value',
                // success
                (snapshot) => {
                    const raw = snapshot.val() || {};  
                    setComputerTime(raw);
                },
                // error
                (error) => {
                    console.log(error);
                });
        }, [uid, currentComputer]);
   
        return (                                       
            <div style={{marginRight:50}}>
                <h3>Daily Computer Usage                        
                    <div className="app-link"><NavLink exact to={"/computer_time"} className="link active">[ See more ]</NavLink></div>                        
                    <div className="app-link"><NavLink exact to={"/time_controls"} className="link active">[ Adjust limits ]</NavLink></div>                        
                </h3>                    
                <ChartComponent data={computerTime} version={version} date={date} week={week}/>
            </div>        
        )
    }
    
    export default withUID(DashboardComputerTime_v2);      
    