import React from 'react'
import logo from '../../images/logo32.png'
import {mainTitle} from '../consts';

const Logo = () => (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems : "center", margin: "-20px -20px 0 -20px", padding: "15px"}}>
        <img src={logo} alt="logo" style={{width : "55px", marginRight : '10px'}}/>                                
        <div style={{color : '#666', fontWeight : '500', fontSize: '1.9rem', margin : '0'}}>                                
            {mainTitle}
        </div>                            
    </div>
)


export default Logo;