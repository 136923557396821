import React, { useState, useEffect } from 'react';
import md5 from 'js-md5';
import firebase from '../../firebase';
import withUID from '../withUID';
import { Icon } from 'semantic-ui-react';

const icons = [
  { name: 'Roblox', icon: 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAABCFBMVEX7///9//////3+/v/////7/fz3///y+PvZ3eP5/f/3+//v8/Ts7vPi5eqYpa+bpaz2///8/f/4/P34+/rt8PbV19zMz9bJzdHFydG/yNGxusOttLuXo6yVoaqLmKP2+vv2+Pns8PTi6fTq7O/l6O3e5Ojc4ObW2uDT197N1N3R1NrDztnJ0dfBy9S7wsu6vsa4vsSotcGls8Cut72jrrmlr7SorrSep66RnqeTnqTx+v7t9v3x9fjh6/f09vXZ5fPn6+/Y4uvR3enN2efQ1+HW3uDJzdS8yNTEzM/Bxc7Cx8qzvcezt8Ctt8Cnsbqqs7ifq7eaqLObpa6aoaedoqWKlJ2NlpuEkZnenLphAAAA4ElEQVQY0y3PVXbEMAxAUTl27EzDzBmedpipzMy4/51UOe3Vh47en0CS5BKllBCKAEB2KgA7CBcpg1t1Ko449Oqa5nllqKlqvXna0jvK7p5PgdhqS28rCl69IAgpMKb7ne6g0b2M7qJxjwGr+dF13Of7jXg0ftQJMC0cxcNzyzp6SFapIGArk+RpenNx0l8szAQIiCCdP79OZ7M3I8+HHECbfJhZZhhFkRXGmQWg3qZLc/uTbzbvX8sDDoiL9uBla67X33OO4Z+4ul99hvgNfktK4NrHTZdUMfxhOLLECPwCDykYIDuoFgEAAAAASUVORK5CYII=' },
  { name: 'WeChat', icon: 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAABCFBMVEX////9//79/f3n6Ojj4+P7/vzq6uoa1Wvl5eUn2m/7+/v29vbw8PDu7u7s7Ozp6enm5ubY+eGi8rcx3XEI0GT5+fns+PLy8vL16vDv7+/g+OvZ29qd4L031YNL6Ho833pQ73hG4XRE3nNK7HJB53Ez4HEn3nAW0Wwoz2clw2EBzGD3/Pn0/PX58fX67PTs8O7l8OrT99/d3d3L8dq+8dbS0tK07s+86M/B982j7MWs68Wt9r2ysrKc9rCZ9K2rrKt73ad86qFy5Jx37pZa441Q3Y1h6YlA14le7oNY734+53ch0Xc94nUq13Q45nI64XI523Ag03AX02wh2WsuuV8kqlUdmEwZj0iD/eIaAAAA1ElEQVQY0y2Ph27DQAxDpdPd1Xa84+y923TvvXebPf//T2IleQAJiCAEEACEUqhiAQKDgp3zOOFIwcFN+PR4H/C5Jnj/rTYa1Z9QbaLgq1mvlcv1WjNEIQDhddhbzP9Gy9mgdcp/oJJM7u22Yn28nVvcME2z3TanlbuTYyeTSSF87jD966x0HUmOBVfjqDOJXg4Lz1I/XOZsELedbtQtHhkF0mdSE0C+VPr+L2ZJe57W5AHmL8R+Ok2GT77hGwnYkiDJ2MjrMAZStuvmaFvgnWu3BK4AZn4T5+KrkNoAAAAASUVORK5CYII=' },
  { name: 'Minecraft', icon: 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAABBVBMVEVpQy2UZEODVjmRYkJUpzajck6BVTlkQCqseVOYaEaWZUWOYEGEWDp9UjV6UDWqd1JrwEeDXUOLXj9OojJuRjBnQitlQCtiPSmXjYiViIKSg3uD0197ylmmdVCgcExmvkRmukNXqzh/Uzd+Ujd3TzRQqDNRlzFkVC9lUi5rQi1qPyxgTipeQSePgHWJ2GRvxkydbUxsxEibakhnwkaHYEZpsUSCW0RasT2HWjxaozpWozZ6UDZwSjRkSTFsRi9mPSthRCplNik7hSaMenGwfFVuy0pstUVqs0RmskJlsUJpoEB0Uz5vTz16Yjl1YzdSnzNkajNzTDNLljJWjzJJkzFoTi9eYi5rNaLBAAAA80lEQVQY0w3MNWLDQBQA0b8gZrBltpjNFDOEmXP/o0TNFFM8GLZXkT5qD0d9at3OqAXtzjru652O7nr0/tGjsFr/FRv69UM9100/HvoQxcX53bpcLDrTz8ndBMr9oLEvy9NkkZyCt00E+e66aWe2vXj6zfHrdwySZCwlMu4laaggMy1AQFhBU40/5goWzOwI2xDqDhBBkbua43AcXMk2VJWW817vcAAAjZHVWr0GzYGBiRmowHdNFqrRaBmsSGQWfEwELmNuiMjzKotECLcSenkeM0RkGJUVphUY4FZrjlkZofonowFXU/1G01B8jERn1+X/ARE6HMXFk/PmAAAAAElFTkSuQmCC' },  
  { name: 'iTunes', icon: 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAABoVBMVEX+/f7////9/////vz9+fj6+Pj6///+/v/y8fPt7vHz6vD3+vnu6O7u7+3X3enf4+j7/Pz6+vv19vn7/Pj29Pf49fb29/Xy9PP38vL07fDp7e/j5+zW5ezU4+vY1+vPz+u43Orwz+rn5emz2uni4+bVzOb54OXR1uLf0eHcy+HqzN3829vv1dr91NX6w8T2tbz0+vqChvk7mfP5+vLv3fK4tfFpUPHd7PDs6fC11PD27e/h0u/r5+631u3s4eyl0+y50OyEueyIsez13evAxurg5enK3OmP0encwunCuOmrp+m4pemIzOjJtuiT0eeNw+eYr+fBnef85Obb3ObWtObs5+Xx3OXi2eWlzuWwt+W0z+Sqz+Tk0+P32eKEeOLG1OGrxOGtweG7rOH94uDz0t9Ii9/Xo96gh922v9zJwNtvpNnxy9izn9fdmNfuyNbwvNLfuNLpu9DFoc/kmM/1xMzppsz3wMjvtsbtwsXRoMTyrb7mp73frrn4tLTbnLT6s7DairD5pqn6p6bkhqbhkZ7scpHkeYHTUHnYWHXeYXLoSmglU3/2AAAA/UlEQVQY0x2P07YDQRRET/dMjxEbN3Zybdu2bdtW8tWZTK162bX2SwEgoBC1lclMYqSBXpzKvn1+5K6jOmrJvqQIg+no3ZGO+DZHGCNxpJ/TF8mSs/JotRruf/P/f6eWc4EF+mxbbGS+Cz9f70lDPAHQdePknMzr06wwF7fUHxghcGUXBPFhuZybivH2fRMETjhJsl0mOEdszcWvm6B6t7VZqjqOuDYOF2XPkghkul+O7K36lJ3KFjlURgFqmghu1lYoantDj2e0DgFrHhny1/hVNTjj7dMEAGQbGOxWwuHetuEQzUJpMfsWxjs7xua9NAb9HksR3u3maYQ1LALOKiqs24RypAAAAABJRU5ErkJggg=='},
  { name: 'WhatsApp', icon: 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAe1BMVEUl02Yo02gk02Uf0mIW0Fwe0WEZ0F7X9+M713Ur1Goi0mRF2X001XDD8tVs4ZdC2Hrc9+bJ9Nku1Wy278yx78id6rlz4p031nP////N9Nut7sWn7MCU6bRx4pto35Rj35JJ2n7o+u/n+e7S9d/O9dyu7sd0451U3IZR3IWr+5PuAAAArElEQVQY0zVPWRaEIAyToVA2RXHfnX3uf8Kh+OwHvKRNm2RUt4xpzeJHdWIOwCNzYS7tp7PSEENYqBrzHJteREB9VbaOaTVjz5NE1q0vDOfQ1JL2CYtuHpRg3JdWZJmGLjfLPYCQuiqAnYQbF7WrQARJngf4/DG0qvS0lcnXDqC3tZ/eku6agOt2MOEmdCYNfBFHrKqycclY9PUL0haFJ+tnEnG7wl1ZSRmfhP9ILQiD/J4qeQAAAABJRU5ErkJggg=='},
  { name: 'minesweeper.exe', icon: 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAABSlBMVEX////+/v7+/f3+///9/Pz3+Pj8+/r7+Pfy8O3T1dyJlaR2gZV8gY9RWXD09PPv8fPg4OTO1Ni0vsXe0cG0uL+bnaWFkaFzd4VYXnP9C1Q0N0gvMEP/AEB9Vyz/ACf98vb/7fDq6+zj5Ob/3ebu6OHp6ODU2N/N0Nb8wdXc2dTMzdCyx83azsz7sMW8vsOutL6msbqerbWlpq+Wn62Pnqvcnaj6gKjItqaQmKXAs6TjmKR8k559iZy3qZnAk5l7hpjGsJfHtZX/T5W6qJP7Z5NveY9zfI5nb4TVcHxoa3hhZXWsnHHkZXCghmhOUmhOVGfmU2esi2bMV2X8IWBITF9CSV/+BVuKfFS5IlNAQlH4Bkz+BUeRckV8Y0P/ADjxBTfxADf6ADH4By6eDyvmCiqKWyl2OSbsACGFIiCqFCB7Th3hABxrRxp+TBmqboXoAAAA3UlEQVQY0z2Pw9YDURAGv+6+dzKMrd+2bdu2mfffZibnJLWsU5sCAWAiQh0KT3WwUiQN1f6/KCIg9rugZ766Xp3sbhKIEAkQkr23y1zubH2grRlBFKKV79u7YrFcfpqIMPsCic/nx5fX/ePZkRh8DJilv6/307l43+50tEuDDdz/bPdrq3MsvXSUCYMMOnkQWPHhcXvzYhSAwnJJq6H5tG07nqtAihK/PRTbcJytbH5HAwyzMgPL87KHhXwvAUTycUDJlHteuMlEUHtdW4DJ7KaiyVYA5BO8tQxqGIwqis4aBmVAuC0AAAAASUVORK5CYII='},
  { name: 'Windows Media Player', icon: 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAABiVBMVEX////+/v79/f7s9Pjs8vXb6fDT4eumz+L7/Pzg6vClzuKgy+GXyOCOxNyryNlnsdb51LXb8v3g8Piz3ff09vbo8PTE3e3R4OmTy+nO3ujM3ej78Oep0OOky+CjyeCvzN6Kvd6VxdySxNySw9ufwtd5sdeOu9V1s9OVu9FzsM75za+3noz4iR7zZBLC6Pz2+fr++vj5+Pjl8fbZ6/TY6/Tj7PH89u/a5e2y1uzg5+vV4uvG3Oa+2uXA2OW81OKzz9+Sxd/X3N6ex92+0NymyNx/tdvP1tqlx9mKvNmKu9mFvNi0ydR4t9SPu9GHuNFssNClvsx6r8tpqMpgoMOYs8H83L2jtL2wubn71betqqOspJuypJrQr5G+oJD2t427pIfhqYX4uX7TpHP2nWnTkGbLllvlmVfinVbcmFP3mlHrkU/2jk7qgEjbg0f0nkb6oETxh0TxjkLVhUDhdzbliTTvcS78giz0hynpbCXybiTyeR/2cx3xaRnyhxX8fxX6hhL3eg/3bQbzYgG1RThIAAAA8UlEQVQY02NABYwMDBycbGxsVmziklJSkqYcQBEhNQ01BVVVVwVPj2A/W31GBhNFMfn4jPSkcGE9H15ZFgYGVh7n1Lx8HZ2SSGF1XhlmRgZWiZjaULPE8rqiAHVldmYGBiHftHoBBoaIzKoofiUuoIBgSGGDAKMBg3SZNr8iO0hAI6tRi4lBOq46mh+shVUioUmAQatAtzjMX4mLhRFoi1tudnKNbqW2nCYvSIUxj5h3SkVpTiy3haYDt6Uhg5GTvKwcH58LN5dMkJe7owgDBwunubi1nT2fikqgDQsjyH9gwCQiyikK5DECERMIgMVBFAAecSYNI2tvJQAAAABJRU5ErkJggg=='},  
  { name: 'Discord', icon: 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAilBMVEX///9WYvb9/f5VYfVTYPVSXvVYY/RRXfVOW/Tt7v29wvqrsPpbZvXw8f3j5P23vPqWnfh7hfd2fvb7+/729v7p6v3a3fzP0vzLzvu5vvqytvmhpvibofeDjPdga/VdafX3+P7y8v3T1fulq/mWnvhuePZsdfZibPZjb/VMWPPDx/vBxfuJkfVxe/WpMX96AAAAxElEQVQY0x2OB47FMAhEB4NLEqe3n/572XL/6629IxjxRgIBKOBUPY15rGOAWJfFCzERH1kXUOU/n0BR7KpzCJaPES3WBiM3h33P0kzpff2eHyJ7gop5L/Cv4WBb4knuFk8pAJWjBoZoxGnL+/qK1JKBl31QpS8G+VKjJo9fefVILkCSoNBcY2WTtUWWllnfLVqnuB4h3jQLbQ3rdw5kjrQmpuh2AlR3d2IoyIi9tQoK5/nlhFnse2rjO6GTsjGmTvM4/wEfVQs4J3108QAAAABJRU5ErkJggg=='},    
];

function AppIcon({ name, uid, currentComputer }) {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    const appIcon = icons.find((app) => app.name === name);
    if (appIcon) {
      setIcon(appIcon.icon);
      return;
    }

    const md5Name = md5(name);
    const storedIcon = localStorage.getItem(md5Name);
    const iconRef = firebase.database().ref(`${uid}/${currentComputer}/icons/${md5Name}`);    

    if (storedIcon) {
      setIcon(storedIcon);
    } else {     
      iconRef.once('value',  // success
        (snapshot) => {
          const newIcon = snapshot.val() || null;
          localStorage.setItem(md5Name, newIcon);
          setIcon(newIcon);
        }, // error
        (error) => {
          console.log(error);
        }
      );
    }
  }, [uid, currentComputer, name]);

  const style = {
    backgroundImage: `url("data:image/png;base64,${icon}")`
  };

  return icon !== 'null' ? (
    <div className="app-icon trunc" style={style}>
      {name}
    </div>
  ) : (
    <div className="trunc">
      <Icon
        name="window maximize outline"
        color="grey"
        style={{ marginLeft: 5, marginRight: 10 }}
      />
      {name}
    </div>
  );
}

export default withUID(AppIcon);