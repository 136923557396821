import React from 'react';
import Applications from './Reports/Applications';
import Websites from './Reports/Websites';
import ComputerTime from './Reports/ComputerTime';
import ComputerTime_v2 from './Reports/ComputerTime_v2';
import WindowsSecurity from './Settings/WindowsSecurity';
import TimeControls from './Settings/TimeControls';
import GeneralSettings from './Settings/GeneralSettings';
import WebsiteBlocking from './Settings/WebsiteBlocking';
import WebsiteFilter from './Settings/WebsiteFilter';
import ApplicationFilter from './Settings/ApplicationFilter';
import UserSettings from './Settings/UserSettings';
import Dashboard from './Dashboard/Dashboard';
import Alerts from './Reports/Alerts';
import Queries from './Reports/Queries';
import {Switch, Route} from 'react-router-dom';
import withUID from './withUID';
import AutorunWizard from './Wizard/AutorunWizard';

const Content = ({version}) => {    
    return (
      <div className="main-content ml-auto static content-scroll">
        <div style={{paddingBottom: 35}}>
          <AutorunWizard/>
          <Switch>             
            <Route path="/" exact component = {Dashboard} />                
            <Route path="/applications" component = {Applications} />
            <Route path="/websites" component = {Websites} />                             
            <Route path="/computer_time" component = {version >= 7601 ? ComputerTime_v2 : ComputerTime} />      
            <Route path="/search_queries" component = {Queries} />                  
            <Route path="/alerts" component = {Alerts} />            
            <Route path="/website_filter" component = {WebsiteFilter} />          
            <Route path="/website_blocking" component = {WebsiteBlocking} />                  
            <Route path="/application_filter" component = {ApplicationFilter} />         
            <Route path="/windows_security" component = {WindowsSecurity} />         
            <Route path="/time_controls" component = {TimeControls} />        
            <Route path="/general_settings" component = {GeneralSettings} /> 
            <Route path="/user_settings" component = {UserSettings} />                    
          </Switch>    
        </div>      
    </div>
    )
}

export default withUID(Content);