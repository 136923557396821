import React from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Popup } from 'semantic-ui-react';
import { emojiToFilename } from '../consts';

// Определение палитры цветов
const colors = ['#4B9AD6', '#33658A', '#C47F62', '#B381B3', '#93B0A0', '#D47500',  "#D83B01", "#B4009E", "#5C2D91",];

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  icon: {
    color: 'white', // Цвет иконки
    fontSize: '1rem', // Размер иконки
  },

}));

const StatusBadge = ({online, status}) => {
    const badgeColor = online !== 'online' ? '#ccc' : status === 'active' ? 'rgb(91, 211, 0)' : 'rgb(233, 186, 21)'; // Желтый для 'stopped', иначе зеленый  
  return (
   <div className='customBadge' style={{ backgroundColor: badgeColor }}></div>
  )
}

const CustomBadgeAvatar = ({edit, online, status = 'active', avatar = '', name = '', index = 0, onClick}) => {
  const classes = useStyles();
  const backgroundColor = avatar ? '#f8f9fa' : colors[index % colors.length];

  const hint = online === 'online' ? `${online}, ${status}` : online;

  const src = avatar ? `/avatars/${emojiToFilename(avatar)}` : '';
  
  const getInitials = (name) => {
    return name.split(" ").map((n) => n[0]).join("");
  };

  return (
    <div className={classes.avatarContainer} onClick={onClick}>
     {src ? (
        <Avatar className={`${classes.avatar} ${online !== 'online' ? classes.grayscale : ''}`} src={src} />
      ) : (
        <Popup
          trigger ={<Avatar className={classes.avatar} style={{ backgroundColor }}>
          {getInitials(name)}
          
        </Avatar>}
        content = {hint}
      />
      )}
      <StatusBadge status={status} online={online}/>
      {edit&&<div className='iconBadge'><CameraAltIcon className={classes.icon}/></div>}     
      
    </div>
  );
};

export default CustomBadgeAvatar;
