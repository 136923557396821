
import React, { PureComponent } from 'react';
import {weekdays, hours} from '../consts';
import './schedule.css';

const Cell = (props) => {
	const {value, x, y} = props;
	const className = `cell ${value === '1' ? 'cell-block' : 'cell-allow'}`;
	return <div onMouseUp={props.onMouseUp} onMouseMove={() => props.onMouseMove(x, y)} onMouseDown={() => props.onMouseDown(x, y)} className={className}></div> 
}

export default class Schedule extends PureComponent {
	defaultSchedule = [
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',
		'000000000000000000000000',										
	]

	state = {
		fill : null
	}

	onMouseDown = (x, y) => {
		let fill = null;		
		const schedule = (this.props.schedule || this.defaultSchedule).map((row, _y)=> {	
			const cells = row.split('').map((cell, _x) => {				
				if ((_x === x - 1) && (_y === y - 1)) { return fill = cell === "1" ? "0" : "1" } else { return cell }
			});
			return cells.join('');			
		});

		this.props.onChange(schedule);
		this.setState({fill});
	}

	onMouseMove = (x, y) => {		
		if (!this.state.fill) return;		
		const {fill} = this.state;		
		const schedule = (this.props.schedule || this.defaultSchedule).map((row, _y)=> {	
			const cells = row.split('').map((cell, _x) => {				
				if ((_x === x - 1) && (_y === y - 1)) { return fill } else {return cell}
			});
			return cells.join('');
		});
		this.props.onChange(schedule);
	}		

	onMouseUp = () => {
		this.setState({fill : null});
	}		

	render() {		
		const schedule = (this.props.schedule || this.defaultSchedule).map((row)=> row.split(''));		
		const midnigth = <div className="half-day">Midnight (AM)</div>
		const noon = <div className="half-day">Noon (PM)</div>

		const hoursRow = hours.map((hour) => <div key={hour.value} className="hour-item">{hour.label}</div>);
		let y = 0;
		const table = schedule.map((row)=> {
			let weekday = <div className="week-title">{weekdays[y]}</div>
			y++;
			let x=0;
			const cells = row.map((cell)=> {
				x++;
				return (<Cell key={x} x={x} y={y} value={cell} onMouseUp={this.onMouseUp} onMouseMove={this.onMouseMove} onMouseDown={this.onMouseDown}/>)
			});

			return (<div key={y} className="item-root">
						{weekday} {cells}
					</div>)
		});

		return (
			<div className="noselect" style={{width : '600px'}}>
				<div className="block"><h3>{this.props.title}</h3></div>
				<div style={{width : '100%', overflowX : 'auto'}}>
					<div style={{minWidth : 500}}>
						<div className="halves-day">{midnigth}{noon}</div>
						<div className="hours-header">{hoursRow}</div>
						<div className="schedule">{table}</div>
					</div>						
				</div>				
			</div>
			);
	}
}