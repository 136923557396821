import React, { Component } from 'react';
import firebase from '../../firebase';
import {Checkbox, Dropdown, Icon} from 'semantic-ui-react';
import Schedule from '../UI/Schedule';
import Slider from '../UI/Slider';
import { minutesToString } from '../../utils';
import { shutdownOptions, modeOptions, warningOptions } from '../consts';
import withUID from './../withUID';
import ApplyButton from './../UI/ApplyButton';
import { applySettings } from './../../Firebase/applySettings';
import ToolTip from '../UI/ToolTip';

class TimeControls extends Component {
    state = {
        settingsRef : null,                  
        settings : {},          
        data : null // updated settings
    }
    
    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                       
        this.setState({settingsRef});
        settingsRef.child('timeControls').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }   
    
    componentWillUnmount() {
        if (this.state.data) {
            this.handleSaveSettings();
        }
    }    

    handleSaveSettings = () => {                
        this.state.settingsRef.child("timeControls").update(this.state.data);
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		
    }

    onChange = (name, value) => {            
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        
    }

    render() {			
        const maxLimit = 145; // 144 - 12 hours... 145 - unlimited
        const {timeLimit = 145, mode = 'day', schedule, showWarning = true, warningTime = 30, shutdown='logout'} = this.state.settings;
        const minutes = mode === 'day' ? timeLimit * 5 : timeLimit * 35;        
        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/>          
				<h2>Screen Time
                    <a href='https://ht-vector.com/ht-family-shield-how-to-use#screen-time-header' rel="noopener noreferrer" target="_blank"><Icon name='question circle' className="help-icon"/></a>                    
                </h2>	                                                
                <div style={{marginBottom : '20px', maxWidth : '550px', paddingRight: 35}}>
                    <h3>Computer usage limit <ToolTip text="Set the maximum amount of computer time allowed per day or week. Adjust the slider to control the total screen time."/></h3>                    
                    <Slider value={timeLimit} min={1} max={maxLimit} onChange={(e, value) => this.onChange('timeLimit', value)}/>
                    <div style={{padding: "10px", paddingTop: "0"}}>
                        {timeLimit < maxLimit ? minutesToString(minutes) : "Unlimited"}
                        {(timeLimit < maxLimit)&&<Dropdown                             
                            style={{margin : 0, marginLeft: "10px"}} 
                            value = {mode}
                            onChange = {(e, {value}) => this.onChange('mode', value)}
                            options={modeOptions}
                        />
                        }                         
                    </div>
                </div>
                <Schedule 
                    title={<>Schedule for Screen Time <ToolTip text="Define specific hours for computer access using the color-coded schedule: green cells represent allowed time, and red cells represent blocked time."/></>}
                    schedule={schedule} onChange={schedule => this.onChange('schedule', schedule)}/>
                <div>
                    <Checkbox onChange={(e, data) => this.onChange('showWarning', data.checked)} checked={showWarning} label='show warning'/>		
                    <Dropdown                             
                            style={{padding: 10, margin : 0}} 
                            value = {warningTime}
                            onChange = {(e, {value}) => this.onChange('warningTime', value)}
                            options={warningOptions()}
                    />                                             
                    before the time is up <ToolTip text="Enable this option to display a warning message X minutes before the allocated computer time ends. Customize the warning time to provide advance notice."/>
                </div>
                <div style={{marginBottm: "20px"}}>                    
                    <Dropdown                                       
                            style={{padding: 10, margin : 0}} 
                            value = {shutdown}
                            onChange = {(e, {value}) => this.onChange('shutdown', value)}
                            options={shutdownOptions()}
                    />
                    when the time is up<ToolTip text="Select the desired action to take when the allocated computer time reaches its limit."/>
                </div>                
           
            </>
        )
    }
}

export default withUID(TimeControls);
 