import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { Dropdown, Loader, Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setComputers, setCurrentComputer } from '../../Store/actions';
import DownloadPage from '../UI/download';
import Computer from './Computer';

const Computers = ({ uid, computers, size, currentComputer, loading, layout, setComputers, setCurrentComputer }) => {
    const [time, setTime] = useState(Date.now());
    const [showDownload, setShowDownload] = useState(false);
    const [aliases, setAliases] = useState({});  

    useEffect(() => {
        if (!uid) return;

        const computersRef = firebase.database().ref(`${uid}/computers`);
        const aliasRef = firebase.database().ref(`${uid}/aliases`);      

        computersRef.on('value', 
            (snapshot) => {
                const computersRaw = Object.values(snapshot.val() || []);
                const computers = computersRaw.filter(item => item.name);

                if (computers.length === 0) 
                    {   
                        computers.push({ name: 'Sample', v: '8.5.2' });
                        setShowDownload(true);                        
                    }
                setComputers(computers);                              
            },
            () => {
                setComputers([]);
            }
        );

        aliasRef.on('value', 
            (snapshot) => {
                const aliases = snapshot.val() || {};
                setAliases(aliases);
            },
        );     

        const interval = setInterval(() => setTime(Date.now()), 10000);
        

        return () => {
            if (computersRef) computersRef.off();
            if (aliasRef) aliasRef.off();           
            clearInterval(interval);
        };
    }, [uid]);

    useEffect(() => {
        if (!currentComputer && computers.length > 0) {
            setCurrentComputer(computers[0].name, computers[0].v);
        } else if (computers.length === 0) {
            setCurrentComputer('null', '');
        }
    }, [computers, currentComputer, setCurrentComputer]);    

    const handleItemClick = (name, version) => {
        setCurrentComputer(name, version);
    };

    const showComputers = (computers, aliases) => (
        computers.map((item, i) => (
            <Menu.Item key={item.name} as='a' name={item.name} active={currentComputer === item.name} onClick={() => handleItemClick(item.name, item.v)}>
                <Computer key={item.name} alias={aliases[item.name]} name={item.name} version={item.v} status={item.s} online={item.online} index={i} />
            </Menu.Item>
        ))
    );

    const dropdownComputers = (computers, aliases) => {
        return (
        computers.map((item, i) => (
            <Dropdown.Item key={item.name} as='a' name={item.name} active={currentComputer === item.name} onClick={() => handleItemClick(item.name, item.v)}>
                <Computer key={item.name} alias={aliases[item.name]} name={item.name} version={item.v} status={item.s} online={item.online} index={i} />
            </Dropdown.Item>

        ))
    )};
      
    const renderSelectedComputer = (current) => (
        <div className="selected-computer">
            <Computer key={current.name} alias={aliases[current.name]} name={current.name} version={current.v} status={current.s} online={current.online} />
        </div>
    );  

    const showDropdownComputers = (currentComputer, computers, aliases) => {
        const current = computers.find(item => item.name === currentComputer); 
        return   (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Dropdown style={{ color : '#666', paddingLeft: 20, paddingRight: 20 }} direction='right'>
                    <Dropdown.Menu style={{marginTop : 20, marginLeft : 40}}>
                        {dropdownComputers(computers, aliases)}
                    </Dropdown.Menu>
                </Dropdown>
                {current && renderSelectedComputer(current)}
            </div>
        );
    };

    if (loading) return <Loader />;

    return (
        <>
            <DownloadPage open={showDownload} handleClose={() => setShowDownload(false)} />
            <div>
                {computers.length > 0 ? (
                    size === 'mini' ? (
                        showDropdownComputers(currentComputer, computers, aliases)
                    ) : (
                        <Menu secondary fluid {...(layout === 'horizontal' ? { horizontal: true } : { vertical: true })}>
                            {showComputers(computers, aliases)}
                        </Menu>
                    )
                ) : (
                    <span>No computers</span>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    computers: state.computers.computers,
    currentComputer: state.computers.currentComputer,
    loading: state.computers.loading,
    uid: state.user.currentUser.uid,
});

export default connect(mapStateToProps, { setComputers, setCurrentComputer })(Computers);