import React from 'react';
import { Table, Icon, Dropdown } from 'semantic-ui-react';
import AppIcon from '../../UI/AppIcon';
import ReportTable from '../../UI/ReportTable';
import { percent, secondsToStringSub } from '../../../utils';
import withAppFilter from '../withAppFilter';
import withUID from '../../withUID';
import ToolTip from '../../UI/ToolTip';

const ApplicationItem = ({item, totalTime, onAddClick}) => {
    const {name, time} = item;         
    const timePercent = percent(time, totalTime);
	return (
        <>
          <Table.Cell><AppIcon key={name} name={name}/></Table.Cell>
          <Table.Cell>{secondsToStringSub(time)}<span style={{float: 'right'}}>{timePercent}</span></Table.Cell>
          <Table.Cell className="time-chart"><div className="green chart" style={{width : timePercent}}></div></Table.Cell>
          <Table.Cell>
            <Dropdown className="actions-dropdown" icon="ellipsis vertical" direction="left" text=''>       
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onAddClick(name, 'block')}>
                        <div><Icon className="block-icon" name="minus circle"/>Block app<ToolTip text='Prevent access to this app.'/></div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onAddClick(name, 'limit')}>
                        <div><Icon className="limit-icon" name="time"/>Limit app<ToolTip text='Enforce time restrictions for this app, guided by your settings from the App Limits tab'/></div>
                    </Dropdown.Item>                    
                </Dropdown.Menu>
            </Dropdown>
        </Table.Cell>         
        </>
	)
}

const collumns = [  
    { title : 'Application', className : 'th-name'},
    { title : 'Time', className : 'th-time'},
    { title : '', className : 'time-chart'},
    { title : ' ', className : 'th-dots'}    
];

const AppChartTable = ({ chart, totalTime, onAddApp }) => {
    return (
      <ReportTable unstackable collumns={collumns} list={chart}>
        {(index, item) => (
          <ApplicationItem
            index={index}
            item={item}
            totalTime={totalTime}
            onAddClick={onAddApp}
          />
        )}
      </ReportTable>
    );
  };
  
  export default withUID(withAppFilter(AppChartTable));