import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import withUID from '../withUID';
import ClearLogsButton from '../UI/ClearLogsButton';
import ComputerTimeTable3 from './tables/ComputerTimeTable_v3';
import { Dropdown } from 'semantic-ui-react';
import { versionToNumber } from '../../utils';


const ComputerTime_v2 = ({uid, currentComputer, version}) => {
    const [computerTime, setComputerTime] = useState([]);
    const [timeRef, setTimeRef] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);

    const handleClearLogs = () => {
        if (timeRef) timeRef.remove();
        const commandsRef = firebase.database().ref(`${uid}/${currentComputer}/commands`);
        const command = { clearComputerTime: firebase.database.ServerValue.TIMESTAMP };
        commandsRef.update(command);
        setComputerTime([]);
    }

    const getComputerTime = () => {
        const timeRef = firebase.database().ref(`${uid}/${currentComputer}/reports/timeByDays`);
        setTimeRef(timeRef);
        timeRef.on('value',
            // success
            (snapshot) => {
                const raw = snapshot.val() || {};    
                const allUsers = Object.keys(raw);
                setUsers(allUsers);
                const computerTime = allUsers.reduce((out, user) => {
                    Object.keys(raw[user]).forEach((date) => {
                        out.push({
                            date,
                            user,
                            ...raw[user][date]
                        })
                    })
                    return out;
                }, []);

                computerTime.sort((a, b) => b.date - a.date);

                setComputerTime(computerTime);
            },
            // error
            (error) => {
                console.log(error);
            });
    }

    const handleUserChange = (event, { value }) => {
        setSelectedUser(value === "" ? "" : value);
    }

    useEffect(() => {
        if (!currentComputer) return;
        getComputerTime();

        return () => {
            if (timeRef) timeRef.off();
        }
    }, []);

    const dropdownOptions = [
        { key: "", value: "", text: "All" },
        ...users.map(user => ({ key: user, value: user, text: user }))
    ];
    
    const filteredComputerTime = selectedUser ?
        computerTime.filter(data => data.user === selectedUser) :
        computerTime;

    return (
        <>
            <ClearLogsButton onClick={handleClearLogs} />
            <h2>Computer Time</h2>
            <div style={{ padding: 10, minWidth: 512}}>
                {users.length > 0 &&
                    <div style={{ marginBottom: 10 }}>
                        Select user:
                        <Dropdown
                            placeholder="All"
                            selection
                            options={dropdownOptions}
                            value={selectedUser}
                            onChange={handleUserChange}
                            style={{marginLeft:10}}
                        />
                    </div>
                }
                <ComputerTimeTable3 version={version} chart={filteredComputerTime} />
            </div>
        </>
    )
}

export default withUID(ComputerTime_v2);