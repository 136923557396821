const domains = [
   "youtube.com",
    "facebook.com",
    "wikipedia.org",
    "reddit.com",
    "instagram.com",
    "tiktok.com",
    "pinterest.com",
    "quora.com",
    "google.com",
    "linkedin.com",
    "twitter.com",
    "amazon.com",
    "apple.com",
    "fandom.com",
    "wordpress.com",
    "yahoo.com",
    "etsy.com",
    "ebay.com",
    "yelp.com",
    "medium.com",
    "nih.gov",
    "walmart.com",
    "imdb.com",
    "indeed.com",
    "tripadvisor.com",
    "nytimes.com",
    "researchgate.net",
    "britannica.com",
    "forbes.com",
    "microsoft.com",
    "mapquest.com",
    "aliexpress.com",
    "shutterstock.com",
    "stackexchange.com",
    "cambridge.org",
    "theguardian.com",
    "scribd.com",
    "alamy.com",
    "wiktionary.org",
    "wikihow.com",
    "reverso.net",
    "sciencedirect.com",
    "bbc.com",
    "dailymotion.com",
    "spotify.com",
    "businessinsider.com",
    "foursquare.com",
    "collinsdictionary.com",
    "indiatimes.com",
    "healthline.com",
    "merriam-webster.com",
    "webmd.com",
    "booking.com",
    "usatoday.com",
    "trip.com",
    "cnn.com",
    "flickr.com",
    "adobe.com",
    "study.com",
    "soundcloud.com",
    "amazon.co.uk",
    "mayoclinic.org",
    "github.com",
    "slideshare.net",
    "istockphoto.com",
    "target.com",
    "dictionary.com",
    "clevelandclinic.org",
    "wikiwand.com",
    "bbc.co.uk",
    "medicalnewstoday.com",
    "wordreference.com",
    "screenrant.com",
    "bab.la",
    "yellowpages.com",
    "waze.com",
    "byjus.com",
    "yahoo.co.jp",
    "genius.com",
    "tumblr.com",
    "usnews.com",
    "ign.com",
    "cnet.com",
    "stackoverflow.com",
    "washingtonpost.com",
    "moovitapp.com",
    "cbsnews.com",
    "ebay.co.uk",
    "rottentomatoes.com",
    "amazon.de",
    "hotels.com",
    "amazon.in",
    "dailymail.co.uk",
    "vocabulary.com",
    "steamcommunity.com",
    "independent.co.uk",
    "geeksforgeeks.org",
    "thefreedictionary.com",
    "deviantart.com",
    "goodreads.com",
    "homedepot.com",
    "freepik.com",
    "netflix.com",
    "springer.com",
    "flipkart.com",
    "europa.eu",
    "redbubble.com",
    "alibaba.com",
    "harvard.edu",
    "archive.org",
    "yourdictionary.com",
    "dreamstime.com",
    "gamespot.com",
    "rome2rio.com",
    "www.nhs.uk",
    "investopedia.com",
    "glassdoor.com",
    "sportskeeda.com",
    "weebly.com",
    "zoominfo.com",
    "ca.gov",
    "gettyimages.com",
    "wikimedia.org",
    "verywellhealth.com",
    "jstor.org",
    "hindustantimes.com",
    "restaurantguru.com",
    "goodhousekeeping.com",
    "nextdoor.com",
    "justdial.com",
    "expedia.com",
    "nature.com",
    "justanswer.com",
    "tvtropes.org",
    "medlineplus.gov",
    "cosmopolitan.com",
    "msn.com",
    "reuters.com",
    "issuu.com",
    "wiley.com",
    "indiamart.com",
    "economictimes.com",
    "agoda.com",
    "gamerant.com",
    "npr.org",
    "today.com",
    "people.com",
    "quizlet.com",
    "company-information.service.gov.uk",
    "ziprecruiter.com",
    "bbb.org",
    "google.co.in",
    "kwai.com",
    "oup.com",
    "bloomberg.com",
    "thesaurus.com",
    "oxfordlearnersdictionaries.com",
    "wordhippo.com",
    "go.com",
    "time.com",
    "google.co.uk",
    "amazon.fr",
    "bestbuy.com",
    "cdc.gov",
    "apnews.com",
    "tvguide.com",
    "latimes.com",
    "pixabay.com",
    "variety.com",
    "amazon.ca",
    "cbr.com",
    "studocu.com",
    "craigslist.org",
    "timeout.com",
    "espn.com",
    "academia.edu",
    "rakuten.co.jp",
    "canva.com",
    "lemon8-app.com",
    "as.com",
    "wattpad.com",
    "nbcnews.com",
    "lowes.com",
    "trustpilot.com",
    "tripadvisor.co.uk",
    "themoviedb.org",
    "www.gov.uk",
    "last.fm",
    "depositphotos.com",
    "infobae.com",
    "lefigaro.fr",
    "temu.com",
    "telegraph.co.uk",
    "zillow.com",
    "buzzfeed.com",
    "msdmanuals.com",
    "cnbc.com",
    "nypost.com",
    "toppr.com",
    "testbook.com",
    "google.ca",
    "amazon.co.jp",
    "steampowered.com",
    "hollywoodreporter.com",
    "uol.com.br",
    "ubereats.com",
    "collider.com",
    "medicinenet.com",
    "ameblo.jp",
    "naver.com",
    "hopkinsmedicine.org",
    "globo.com",
    "glosbe.com",
    "elpais.com",
    "cbc.ca",
    "indianexpress.com",
    "samsung.com",
    "softonic.com",
    "khanacademy.org",
    "ikea.com",
    "urbandictionary.com",
    "ny.gov",
    "vecteezy.com",
    "parade.com",
    "barnesandnoble.com",
    "ew.com",
    "canada.ca",
    "techradar.com",
    "vimeo.com",
    "ouest-france.fr",
    "pbs.org",
    "shabdkosh.com",
    "deezer.com",
    "ldoceonline.com",
    "digitaltrends.com",
    "google.de",
    "allrecipes.com",
    "line.me",
    "crunchbase.com",
    "cornell.edu",
    "dnb.com",
    "costco.com",
    "wikidata.org",
    "realtor.com",
    "kayak.com",
    "news18.com",
    "ndtv.com",
    "mit.edu",
    "lavanguardia.com",
    "thegamer.com",
    "primevideo.com",
    "discogs.com",
    "chegg.com",
    "pinterest.es",
    "medscape.com",
    "usda.gov",
    "wired.com",
    "marca.com",
    "pcmag.com",
    "shazam.com",
    "ebay.ca",
    "groupon.com",
    "elle.com",
    "oed.com",
    "tenor.com",
    "mirror.co.uk",
    "vedantu.com",
    "who.int",
    "city-data.com",
    "thesun.co.uk",
    "makeuseof.com",
    "thoughtco.com",
    "accuweather.com",
    "tomsguide.com",
    "kleinanzeigen.de",
    "justwatch.com",
    "makemytrip.com",
    "theverge.com",
    "kidshealth.org",
    "libretexts.org",
    "ranker.com",
    "pons.com",
    "ebay.de",
    "rocketreach.co",
    "jiosaavn.com",
    "rollingstone.com",
    "wsj.com",
    "bandcamp.com",
    "tandfonline.com",
    "aminoapps.com",
    "pagesjaunes.fr",
    "abcmillwork.com",
    "metacritic.com",
    "theconversation.com",
    "detik.com",
    "si.com",
    "texas.gov",
    "statista.com",
    "betterhealth.vic.gov.au",
    "prom.ua",
    "unsplash.com",
    "biomedcentral.com",
    "mundodeportivo.com",
    "lifewire.com",
    "healthdirect.gov.au",
    "gamesradar.com",
    "manta.com",
    "mountsinai.org",
    "vulture.com",
    "tistory.com",
    "health.com",
    "houzz.com",
    "amazon.es",
    "rateyourmusic.com",
    "travelocity.com",
    "boomplay.com",
    "billboard.com",
    "letras.com",
    "uptodown.com",
    "salary.com",
    "wa.gov",
    "marriott.com",
    "indiatoday.in",
    "thehindu.com",
    "allevents.in",
    "tokopedia.com",
    "foodnetwork.com",
    "stanford.edu",
    "umich.edu",
    "prnewswire.com",
    "musixmatch.com",
    "movieweb.com",
    "nsw.gov.au",
    "abc.net.au",
    "google.co.id",
    "everydayhealth.com",
    "instructables.com",
    "mdpi.com",
    "prezi.com",
    "goo.ne.jp",
    "olx.ua",
    "ft.com",
    "newsweek.com",
    "sagepub.com",
    "howstuffworks.com",
    "un.org",
    "kompas.com",
    "namu.wiki",
    "alberta.ca",
    "aol.com",
    "popsugar.com",
    "brainly.in",
    "express.co.uk",
    "southernliving.com",
    "commonsensemedia.org",
    "travelweekly.com",
    "hinative.com",
    "polygon.com",
    "nasa.gov",
    "cookpad.com",
    "dzen.ru",
    "capcut.com",
    "goal.com",
    "apkpure.com",
    "amazon.com.au",
    "radiotimes.com",
    "standard.co.uk",
    "behance.net",
    "mercari.com",
    "livemint.com",
    "airbnb.com",
    "menshealth.com",
    "pngwing.com",
    "doordash.com",
    "tribunnews.com",
    "brainly.com",
    "kiddle.co",
    "weather.com",
    "goodrx.com",
    "wordnik.com",
    "redfin.com",
    "loc.gov",
    "womenshealthmag.com",
    "gutefrage.net",
    "psu.edu",
    "nationalgeographic.com",
    "pinterest.co.uk",
    "123rf.com",
    "rozetka.com.ua",
    "india.com",
    "esquire.com",
    "coursehero.com",
    "superpages.com",
    "dexerto.com",
    "patch.com",
    "elespanol.com",
    "letras.mus.br",
    "deadline.com",
    "substack.com",
    "pinterest.de",
    "lemonde.fr",
    "mumsnet.com",
    "bbcgoodfood.com",
    "yandex.ru",
    "abplive.com",
    "countryliving.com",
    "frontiersin.org",
    "mercadolivre.com.br",
    "digitalspy.com",
    "pngtree.com",
    "linguee.com",
    "hubspot.com",
    "thespruce.com",
    "elmundo.es",
    "nps.gov",
    "google.com.ua",
    "metro.co.uk",
    "techtarget.com",
    "opentable.com",
    "huffpost.com",
    "semanticscholar.org",
    "vk.com",
    "rd.com",
    "amazon.it",
    "poshmark.com",
    "viator.com",
    "cancer.gov",
    "letterboxd.com",
    "fodors.com",
    "realsimple.com",
    "jooble.org",
    "macys.com",
    "hm.com",
    "abebooks.com",
    "mashable.com",
    "mubi.com",
    "vectorstock.com",
    "investing.com",
    "javatpoint.com",
    "orbitz.com",
    "tripadvisor.ca",
    "state.gov",
    "eventbrite.com",
    "answers.com",
    "sofascore.com",
    "ultimate-guitar.com",
    "ebay.com.au",
    "grubhub.com",
    "mercadolibre.com.mx",
    "amazon.com.br",
    "euronews.com",
    "liputan6.com",
    "food.com",
    "xbox.com",
    "yumpu.com",
    "cbssports.com",
    "refinery29.com",
    "kumparan.com",
    "note.com",
    "mojim.com",
    "made-in-china.com",
    "chron.com",
    "bleacherreport.com",
    "sportingnews.com",
    "umn.edu",
    "thepioneerwoman.com",
    "drugs.com",
    "dw.com",
    "shiksha.com",
    "google.co.jp",
    "foxsports.com",
    "nerdwallet.com",
    "klook.com",
    "definitions.net",
    "pexels.com",
    "uptodate.com",
    "livejournal.com",
    "lawinsider.com",
    "timesnownews.com",
    "eater.com",
    "wayfair.com",
    "timeanddate.com",
    "shopee.co.id",
    "azlyrics.com",
    "roku.com",
    "shein.com",
    "playstation.com",
    "harpersbazaar.com",
    "legacy.com",
    "archiveofourown.org",
    "business-standard.com",
    "marketwatch.com",
    "alltrails.com",
    "yelp.ca",
    "ibm.com",
    "trulia.com",
    "foxnews.com",
    "apartments.com",
    "wise.com",
    "pinterest.fr",
    "aljazeera.com",
    "google.com.au",
    "si.edu",
    "theknot.com",
    "almaany.com",
    "kroger.com",
    "zippia.com",
    "vogue.com",
    "fda.gov",
    "nordstrom.com",
    "noaa.gov",
    "ufl.edu",
    "tureng.com",
    "mapcarta.com",
    "the-sun.com",
    "fnac.com",
    "jagran.com",
    "twitch.tv",
    "rogerebert.com",
    "google.fr",
    "justia.com",
    "20minutos.es",
    "shopify.com",
    "powerthesaurus.org",
    "filmaffinity.com",
    "unirioja.es",
    "newyorker.com",
    "lww.com",
    "columbia.edu",
    "superuser.com",
    "virginia.gov",
    "lumenlearning.com",
    "aaa.com",
    "asos.com",
    "comicbook.com",
    "mappy.com",
    "tripadvisor.in",
    "ctvnews.ca",
    "aarp.org",
    "va.gov",
    "teacherspayteachers.com",
    "vrbo.com",
    "abc.es",
    "rochester.edu",
    "rakuten.com",
    "mynavi.jp",
    "tasteofhome.com",
    "etymonline.com",
    "lyrics.com",
    "psychologytoday.com",
    "thespruceeats.com",
    "giphy.com",
    "oxfordreference.com",
    "scmp.com",
    "mozilla.org",
    "bmj.com",
    "eatingwell.com",
    "unacademy.com",
    "thekitchn.com",
    "terra.com.br",
    "livescience.com",
    "magazineluiza.com.br",
    "vox.com",
    "pitchbook.com",
    "pressbooks.pub",
    "tripadvisor.co",
    "fastercapital.com",
    "trivago.com",
    "healthgrades.com",
    "hamariweb.com",
    "famousbirthdays.com",
    "sky.com",
    "nyc.gov",
    "wondershare.com",
    "skysports.com",
    "tutorialspoint.com",
    "allmusic.com",
    "larousse.fr",
    "iheart.com",
    "consumerreports.org",
    "cntraveler.com",
    "history.com",
    "jamanetwork.com",
    "mercadolibre.com.ar",
    "wordsmyth.net",
    "cvs.com",
    "lonelyplanet.com",
    "lyricstranslate.com",
    "google.it",
    "nymag.com",
    "loopnet.com",
    "berkeley.edu",
    "plex.tv",
    "caranddriver.com",
    "howtogeek.com",
    "tvinsider.com",
    "wisc.edu",
    "moneycontrol.com",
    "rae.es",
    "yale.edu",
    "travelandleisure.com",
    "eonline.com",
    "amarujala.com",
    "nhsinform.scot",
    "ebay.fr",
    "francetvinfo.fr",
    "navitime.co.jp",
    "cdiscount.com",
    "vinmec.com",
    "yell.com",
    "epa.gov",
    "hinkhoj.com",
    "apa.org",
    "nike.com",
    "spanishdict.com",
    "johnlewis.com",
    "lanacion.com.ar",
    "omnicalculator.com",
    "glamour.com",
    "jagranjosh.com",
    "pngegg.com",
    "theatlantic.com",
    "traveloka.com",
    "verywellmind.com",
    "usmagazine.com",
    "bol.com",
    "elconfidencial.com",
    "marthastewart.com",
    "kohls.com",
    "oracle.com",
    "vice.com",
    "focus.de",
    "leboncoin.fr",
    "google.com.br",
    "tripadvisor.fr",
    "leparisien.fr",
    "bustle.com",
    "google.co.kr",
    "news-medical.net",
    "foodandwine.com",
    "smh.com.au",
    "howtopronounce.com",
    "core.ac.uk",
    "blibli.com",
    "angi.com",
    "smule.com",
    "mass.gov",
    "delish.com",
    "restaurantji.com",
    "pa.gov",
    "mercadolibre.com.co",
    "epicentrk.ua",
    "homes.com",
    "amazon.com.mx",
    "nikkei.com",
    "babycenter.com",
    "wynk.in",
    "eurogamer.net",
    "fc2.com",
    "familysearch.org",
    "songfacts.com",
    "cinemablend.com",
    "byrdie.com",
    "unam.mx",
    "walgreens.com",
    "bringfido.com",
    "hp.com",
    "clarin.com",
    "casetext.com",
    "purdue.edu",
    "pcgamer.com",
    "hatenablog.com",
    "menupix.com",
    "globalnews.ca",
    "artstation.com",
    "pennmedicine.org",
    "studysmarter.co.uk",
    "fortune.com",
    "wikimapia.org",
    "thebump.com",
    "apkcombo.com",
    "in.gov",
    "reserving.com",
    "metafilter.com",
    "nj.gov",
    "mlb.com",
    "elcomercio.pe",
    "langeek.co",
    "michigan.gov",
    "pinterest.com.mx",
    "chordify.net",
    "upenn.edu",
    "ycombinator.com",
    "ea.com",
    "walmart.ca",
    "roadtrippers.com",
    "ucdavis.edu",
    "livestrong.com",
    "tuasaude.com",
    "prtimes.jp",
    "zmenu.com",
    "priceline.com",
    "ieee.org",
    "wanderlog.com",
    "olympics.com",
    "washington.edu",
    "aajtak.in",
    "corriere.it",
    "edmunds.com",
    "osu.edu",
    "zendesk.com",
    "kbb.com",
    "ingles.com",
    "hilton.com",
    "seriouseats.com",
    "bonappetit.com",
    "livehindustan.com",
    "tripadvisor.de",
    "smithsonianmag.com",
    "toasttab.com",
    "dribbble.com",
    "mo.gov",
    "squarespace.com",
    "cylex-uk.co.uk",
    "instacart.com",
    "cars.com",
    "arcgis.com",
    "t-online.de",
    "pharmeasy.in",
    "androidauthority.com",
    "weather.gov",
    "idealo.de",
    "findmeglutenfree.com",
    "decider.com",
    "prokerala.com",
    "kompass.com",
    "prevention.com",
    "ancestry.com",
    "birdeye.com",
    "educalingo.com",
    "untappd.com",
    "urdupoint.com",
    "stern.de",
    "google.es",
    "menards.com",
    "seamless.com",
    "goibibo.com",
    "abril.com.br",
    "pixnet.net",
    "distractify.com",
    "pixiv.net",
    "msu.edu",
    "arxiv.org",
    "youm7.com",
    "theculturetrip.com",
    "threads.net",
    "allocine.fr",
    "samsclub.com",
    "fool.com",
    "nme.com",
    "lastminute.com",
    "daum.net",
    "opencorporates.com",
    "spiegel.de",
    "pinterest.jp",
    "ck12.org",
    "masterclass.com",
    "zdnet.com",
    "firstcry.com",
    "hgtv.com",
    "www.gob.mx",
    "uchicago.edu",
    "w3schools.com",
    "news.com.au",
    "simplyhired.com",
    "france24.com",
    "findlaw.com",
    "slate.com",
    "brainly.lat",
    "patient.info",
    "zara.com",
    "gaana.com",
    "verizon.com",
    "ct.gov",
    "acs.org",
    "hrs.com",
    "gymglish.com",
    "grid.id",
    "gotquestions.org",
    "dot.gov",
    "nexusmods.com",
    "apkpure.net",
    "chip.de",
    "shopee.com.br",
    "bartleby.com",
    "udemy.com",
    "tabelog.com",
    "nintendo.com",
    "worldbank.org",
    "spiceworks.com",
    "churchofjesuschrist.org",
    "gramedia.com",
    "usembassy.gov",
    "bfmtv.com",
    "data.gouv.fr",
    "kakaku.com",
    "hola.com",
    "unc.edu",
    "illinois.gov",
    "udn.com",
    "motortrend.com",
    "hotelplanner.com",
    "songmeanings.com",
    "lenovo.com",
    "tubitv.com",
    "idntimes.com",
    "utexas.edu",
    "lovetoknow.com",
    "illinois.edu",
    "otto.de",
    "dickssportinggoods.com",
    "thewrap.com",
    "momondo.com",
    "state.mn.us",
    "20minutes.fr",
    "planetofhotels.com",
    "michelin.com",
    "ox.ac.uk",
    "dell.com",
    "univision.com",
    "actu.fr",
    "architecturaldigest.com",
    "eltiempo.com",
    "aptoide.com",
    "lazada.co.id",
    "scientificamerican.com",
    "rent.com",
    "ed.gov",
    "deepl.com",
    "momjunction.com",
    "wordwall.net",
    "tripadvisor.com.au",
    "pikiran-rakyat.com",
    "singleplatform.com",
    "pinkvilla.com",
    "journaldesfemmes.fr",
    "verywellfit.com",
    "ucla.edu",
    "self.com",
    "t.me",
    "nasdaq.com",
    "carfax.com",
    "oregon.gov",
    "aafp.org",
    "weblio.jp",
    "bankrate.com",
    "marketscreener.com",
    "thriftbooks.com",
    "aiscore.com",
    "postmates.com",
    "ihg.com",
    "welt.de",
    "trendyol.com",
    "audible.com",
    "housebeautiful.com",
    "cedars-sinai.org",
    "farfetch.com",
    "scielo.br",
    "hse.ie",
    "doubtnut.com",
    "free.fr",
    "translated.net",
    "radiopaedia.org",
    "yellowpages.ca",
    "okdiario.com",
    "meinestadt.de",
    "ozon.ru",
    "worldatlas.com",
    "bigl.ua",
    "coursera.org",
    "ukr.net",
    "hubpages.com",
    "freecodecamp.org",
    "songtexte.com",
    "ohio.gov",
    "meteoblue.com",
    "twinkl.co.uk",
    "gq.com",
    "teenvogue.com",
    "vaia.com",
    "getyourguide.com",
    "denofgeek.com",
    "whosampled.com",
    "hbr.org",
    "ludwig.guru",
    "leverageedu.com",
    "infinitylearn.com",
    "openedition.org",
    "cvent.com",
    "hellomagazine.com",
    "allegro.pl",
    "mawdoo3.com",
    "biblestudytools.com",
    "my-best.com",
    "asahi.com",
    "tradingview.com",
    "allure.com",
    "cargurus.com",
    "streetdirectory.com",
    "hotelscombined.com",
    "resetera.com",
    "pcgamesn.com",
    "socratic.org",
    "grammarly.com",
    "ncsu.edu",
    "linguee.fr",
    "yardbarker.com",
    "faz.net",
    "cuemath.com",
    "whattoexpect.com",
    "beebom.com",
    "collegedunia.com",
    "societe.com",
    "diy.com",
    "tr-ex.me",
    "dcinside.com",
    "ucsd.edu",
    "usgs.gov",
    "vanityfair.com",
    "merckmanuals.com",
    "pinterest.ca",
    "indiewire.com",
    "cnnindonesia.com",
    "emedicinehealth.com",
    "ebay.it",
    "tsn.ua",
    "dotesports.com",
    "heb.com",
    "bookmyshow.com",
    "biblegateway.com",
    "wikivoyage.org",
    "niche.com",
    "jalan.net",
    "tesco.com",
    "voanews.com",
    "dbpedia.org",
    "bhaskar.com",
    "tripadvisor.it",
    "minitool.com",
    "business.site",
    "tamu.edu",
    "bu.edu",
    "francebleu.fr",
    "autoblog.com",
    "argentina.gob.ar",
    "elsevier.es",
    "linternaute.fr",
    "imgur.com",
    "tripsavvy.com",
    "ricksteves.com",
    "etonline.com",
    "cleartax.in",
    "purewow.com",
    "docs.google.com",
    "drive.google.com",
    "discord.com",
    "roblox.com",
    "myaccount.google.com",
    "chat.openai.com",
    "x.com",
    "chess.com",
    "outlook.live.com",
    "meet.google.com",
    "play.google.com",
    "open.spotify.com",
    "youtubekids.com",
    "classroom.google.com",
    "character.ai",
    "pw.live",
    "scratch.mit.edu",
    "outlook.live.com",
    "family.ht-vector.com",
    "mail.google.com",
    "translate.google.com",
    "search.google.com",
    "chatgpt.com",
    "bing.com"
]

export default domains;