import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Form, TextArea } from 'semantic-ui-react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions';

const EditList = (props) => {
    const list = props.list.join('\n');    
    const [value, setValue] = useState(list);

    const handleSave = () => {
        const list = value.split(/[\n,]/);
        const out = list.filter((item, index) => (list.indexOf(item) === index) && (item !== ''))
        props.handleSave(props.name, out.slice(0, 1000));
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={true}
        maxWidth="md"
    >
        <DialogTitle>
            {props.title}
            <div style={{position: "absolute", top: "8px", right: "10px"}}>                
                <IconButton aria-label="close" onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
        </DialogTitle>
        <DialogContent>
          <Form spellCheck="false">
            <TextArea autoFocus spellCheck="false" style={{ marginBottom : 20, minHeight: 200 }} onChange={handleChange} value={value} placeholder={props.title}/>
            </Form>            
        </DialogContent>   
        <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <Button onClick={handleSave}>OK</Button>
        </DialogActions>                   
    </Dialog>
    );
}

export default EditList;