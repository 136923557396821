import React from 'react'
import {percent, secondsToString} from '../../../utils';
import ReportTable from '../../UI/ReportTable';
import moment from 'moment';

const ComputerTimeItem = (props) => {
	const {date, user = '', activeTime = 0, idleTime = 0} = props.item; 
	const {scale} = props;
	const timeWidth = percent(activeTime - idleTime, scale);
    const idleTimeWidth = percent(idleTime, scale);	
    const rawDate = moment(date, 'YYMMDD');
    const dateStr = rawDate.year() === moment().year() ? rawDate.format("ddd D MMM") : rawDate.format("ddd D MMM YY")
    const alpha = activeTime > 0 ? idleTime/activeTime : 0;

	return (
		<>
			<td>{dateStr}</td>				
			<td>{user}</td>								
			<td>{secondsToString(activeTime)}</td>
			<td style={{backgroundColor : `rgba(255, 223, 64, ${alpha})`}}>{secondsToString(idleTime)} <span style={{float: 'right', fontWeight: 700, color : '#666'}}> {percent(idleTime, activeTime)}</span></td>	
			<td><div><div className="green chart" style={{width : timeWidth}}></div><div className="yellow chart" style={{width : idleTimeWidth}}></div></div>         
            </td>		
		</>
	)
}

const ComputerTimeTable = ({chart}) => {
    const max = chart.reduce((max, item) => Math.max(max, item.activeTime), 3600);    
    const scaleMax = Math.ceil(max / 3600) * 3600;    
    const scaleText = <><div className="scale">{secondsToString(scaleMax / 2)}</div><div className="scale">{secondsToString(scaleMax)}</div></>

    const collumns = [			
        { title : 'Date', className : 'th-fixed-160'},
        { title : 'User', className : 'th-fixed-160'},
        { title : 'Total Time', className : 'th-fixed-160'},
        { title : 'Idle Time', className : 'th-fixed-160'},	
        { title : scaleText, className : 'th-variant'},
    ];    
    return (
        <ReportTable collumns={collumns} list={chart}>
        { (index, item) => <ComputerTimeItem index={index} item={item} scale={scaleMax} />}
        </ReportTable>
    );
  };
  
  export default ComputerTimeTable;