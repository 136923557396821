import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { avatars, emojiToFilename } from '../consts';

const AvatarDialog = (props) => {
    return (
        <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="avatar-dialog-title">
            <DialogTitle id="avatar-dialog-title">Select an Avatar</DialogTitle>
            <DialogContent>
                <div className="avatar-grid">
                    {avatars.map((avatar, index) => (
                        <div
                            key={index}
                            className="avatar-item"
                            style={{ fontSize: '32px', cursor: 'pointer' }}
                            onClick={() => props.handleOK(avatar)}
                        >
                            {index === 0 ? '' : <img 
                                src={`/avatars/${emojiToFilename(avatar, index)}`}                                 
                                className="avatar-image" 
                            />}
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AvatarDialog;
