import React from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import {Icon} from 'semantic-ui-react';
import "react-datepicker/dist/react-datepicker.css";

 const DatePicker_2023 = ({onChangeDate, date = moment(), includes}) => {
    return (				
        <DatePicker 
            selected={date.toDate()}
            customInput={<span><Icon style={{marginLeft : '5px'}} color={'grey'} name="calendar alternate outline"/></span>}
            onChange={onChangeDate}
            includeDates={includes}
            popperPlacement='bottom-end' 
        />	            		
	);	
}

export default DatePicker_2023;
