import React, { Component } from 'react';
import firebase from '../../firebase';
import {Dropdown, List, Image, Icon, Message} from 'semantic-ui-react';
import Schedule from '../UI/Schedule';
import Slider from '../UI/Slider';
import {minutesToString} from '../../utils';
import {webCategories, modeOptions, modeOptions2, statusOptions} from '../consts';
import withUID from './../withUID';
import ApplyButton from './../UI/ApplyButton';
import { applySettings } from './../../Firebase/applySettings';
import WebsiteListWithSearch from './../UI/WebsiteListWithSearch';
import ToolTip from '../UI/ToolTip';
import Status from '../UI/Status';

class WebsiteFilter extends Component {
    state = {
        settingsRef : null,                
        settings : {},          
        data : null, // updated settings
    }
    
    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                      
        this.setState({settingsRef});
        settingsRef.child('websiteFilter').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }     

    componentWillUnmount() {
        if (this.state.data) {
            this.handleSaveSettings();
        }
    }    

    handleSaveSettings = () => {                
        this.state.settingsRef.child("websiteFilter").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		
    }

    onChange = (name, value) => {         
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        
    }

    render() {			
        const maxLimit = 121; // 120 - 10 hours, 121 - Unlimited
        const {blacklist = [], timeLimit = 121, mode = 'day', schedule} = this.state.settings;    
        const minutes = timeLimit * modeOptions2.find(item => item.value === mode).multiplier;    
        const {version} = this.props;
               
        // Categories
        const filteredWebCategories = version < 9501 ? webCategories.filter(item => item.label !== 'News') : webCategories;     
        
        const webCategoriesList = filteredWebCategories.map((item) => (            
        <List.Item key={item.name}>
            <div className='blacklist-container' style={{padding: "0.3rem"}}>
                <div className='blacklist-title'>
                    <Image floated='left' style={{opacity: '0.6', width: '1.3em', height: '1.3em'}} src={item.image}/>
                    <div style={{color : 'rgba(0,0,0,.87)'}}>{item.label}</div>                    
                </div>  
                <div className='blacklist-status' style={{marginRight: '55px'}}>
                <Status status={this.state.settings[item.name]} onChange={(status) => this.onChange(item.name, status)}/>
                </div>                                                                                      
            </div>          
        </List.Item>                            
    ));         

        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/>               
				<h2>Website Limits
                    <a href='https://ht-vector.com/ht-family-shield-how-to-use#time-limits-header' rel="noopener noreferrer" target="_blank"><Icon name='question circle' className="help-icon"/></a>                                        
                </h2>	                                                                              
                <div className="flex-box" style={{ marginTop: "-15px"}}>
                        {(version >= 6101)&&<div style={{maxWidth : '550px', width : '100%', marginTop : "0px", marginRight: "10px"}}>                                            
                            <h3>Website categories</h3>                    
                            <div style={{marginTop : "0px", marginRight: "10px", maxHeight: '450px', overflowY: 'auto'}}>                          
                                <List selection size="large">{webCategoriesList}</List>  
                            </div>                           
                            </div> }                      
                        <div style={{maxWidth : '550px', width : '100%', marginTop : "0px", marginRight: "10px"}}>                                                                        
                            <h3>Custom list</h3>                        
                            <WebsiteListWithSearch list={blacklist} name='blacklist' onChange={this.onChange} style = {{padding : 5, marginRight : 15,  height : '400px', overflowY: 'auto', border : '1px solid #ddd'}}/>                                                             
                        </div>  
                 </div>
                 <div className="flex-box">                               
                    <div style={{maxWidth : "550px", width: '100%', paddingRight: '35px'}}>
                        <h3>Max time on limited sites / categories <ToolTip text="Set the maximum usage time for limited websites and categories. This limit is in effect during the scheduled access times defined as allowed."/></h3>                                            
                            <Slider value={timeLimit} min={1} max={maxLimit} onChange={(e, value) => this.onChange('timeLimit', value)}/>
                            <div style={{padding : "10px", paddingTop: "0"}}>
                                {timeLimit < maxLimit ? minutesToString(minutes) : "Unlimited"}
                                {(timeLimit < maxLimit)&&<Dropdown                             
                                    style={{margin : 0, marginLeft: "10px"}} 
                                    value = {mode}
                                    onChange = {(e, {value}) => this.onChange('mode', value)}
                                    options={version >= 4801 ? modeOptions2 : modeOptions}
                                />}                     
                            </div>        
                    </div>                      
                    <Schedule 
                        title={<>Access schedule for limited sites / categories<ToolTip text="Define the hours during which limited websites and categories can be accessed."/></>}
                        schedule={schedule} 
                        onChange={schedule => this.onChange('schedule', schedule)}
                    />                                                                                      
                </div>    
                <Message info style={{marginBottom : 20}}>
                <div style={{marginTop: 10}}><Icon name="check circle" style={{fontSize : "18px", color : "#28a745", marginRight: 8, float : "left"}}/>Allow: No restrictions applied.  </div>    
                <div style={{marginTop: 10}}> <Icon name="minus circle" style={{fontSize : "18px", color : "#dc3545", marginRight: 8, float : "left"}}/>Block: Access completely denied</div>                
                <div style={{marginTop: 10}}><Icon name="time" style={{fontSize : "18px", color : "#f5c242", marginRight: 8, float : "left"}}/>Limit: Access restricted according to the schedule and time limits</div>     
                </Message>                    
            </>
        )
    }
}

export default withUID(WebsiteFilter);
 