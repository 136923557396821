import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import withUID from './../withUID';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

    const formatTime = (timeInSeconds) => {      
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        
        if (minutes < 60) {
            return `${minutes}m ${seconds}s`;
        }
        
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        return remainingMinutes > 0 ? `${hours}h ${remainingMinutes}m` : `${hours}h`;
    };
      
    const ChartComponent = ({data, date, week}) => {    
    const startDate = week ? moment(date).startOf('isoWeek') : moment(date).subtract(6, 'days');
    const days = Array.from({ length: 7 }, (_, i) => moment(startDate).add(i, 'days'));
    
    const chartData = days.map((day) => {

        const dayKey = day.format('YYMMDD');
        const dayData = data[dayKey];
    
        let activeTime = 0;
        let idleTime = 0;
    
        if (dayData) {
            Object.values(dayData).forEach((userData) => {
                activeTime += parseInt(userData.activeTime || 0);
                idleTime += parseInt(userData.idleTime || 0);
            });
        }
    
        return { dayKey, activeTime, idleTime };
    });
    
    let maxTotalTime = Math.max(
        ...chartData.map(({activeTime}) => activeTime)
    );

    maxTotalTime =  (maxTotalTime < 3600) ? Math.ceil(maxTotalTime / 240) * 240 : Math.ceil(maxTotalTime / 3600) * 3600 // round
    
    const yAxisLabels = [0.25, 0.5, 0.75, 1].map((percentage) => {
        const timeInSeconds = Math.floor(maxTotalTime * percentage);
        return formatTime(timeInSeconds);
    }).reverse();

    const overlayLines = Array.from({ length: 5 }, (_, i) => (
        <div key={i} className="line-overlay" style={{top : `${i * 25}%`}}></div>
    ));
    
    const chartBars = chartData.map(({ dayKey, activeTime, idleTime }) => {
        const activeHeight = ((activeTime-idleTime) / maxTotalTime) * 100;
        const idleHeight = (idleTime / maxTotalTime) * 100;          
        return (
            <div key={dayKey} className="chart-bar">
                <div
                    className="chart-bar-segment chart-bar-idle"
                    style={{ height: `${Math.max(idleHeight, 0.01)}%` }}
                ></div>
                <div
                    className="chart-bar-segment chart-bar-active"
                    style={{ height: `${Math.max(activeHeight, 0.3)}%` }}
                ></div>
                <div className="chart-bar-label">{moment(dayKey, 'YYMMDD').format('DD MMM')}</div>
            </div>
        );
        });
    
    return (
        <div className="chartComputer">
        <div className="chart-y-axis">
            {yAxisLabels.map((label, index) => (
            <div key={index} className="chart-y-axis-label" style={{position: "absolute", top: `${index * 25}%`}}>
                {label}
            </div>
            ))}
        </div>
        {chartBars}
        <div className="chart-overlay">{overlayLines}</div>        
        </div>
    );
    };

      const DashboardComputerTime = ({uid, currentComputer, date, week}) => {
        const [computerTime, setComputerTime] = useState([]);
    
        useEffect(() => {
            if (!currentComputer) return;
            const timeRef = firebase.database().ref(`${uid}/${currentComputer}/reports/time`);            
            timeRef.limitToLast(7).once('value',
                // success
                (snapshot) => {
                    const raw = snapshot.val() || {};  
                    setComputerTime(raw);
                },
                // error
                (error) => {
                    console.log(error);
                });
        }, [uid, currentComputer]);
    
        return (
            <>                                
                <div style={{marginRight:50}}>
                    <h3>Daily Computer Usage                        
                        <div className="app-link"><NavLink exact to={"/computer_time"} className="link active">[ See more ]</NavLink></div>                        
                        <div className="app-link"><NavLink exact to={"/time_controls"} className="link active">[ Adjust limits ]</NavLink></div>                        
                    </h3>                    
                 <ChartComponent data={computerTime} date={date} week={week}/>
                </div>
            </>
        )
    }
    
    export default withUID(DashboardComputerTime);      
    