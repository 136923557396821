import React, { useState, useEffect } from 'react'
import firebase from '../../firebase';
import ReportTable from '../UI/ReportTable';
import {Table, Icon, Dropdown} from 'semantic-ui-react';
import moment from 'moment';
import WebIcon from '../UI/WebIcon';
import withUID from './../withUID';
import withWebFilter from './withWebFilter';
import ToolTip from '../UI/ToolTip';

const WebsiteItem = ({item, onAddClick}) => {
    const {url, title, time} = item;      
    const link = /^(f|ht)tps?:\/\//i.test(url) ? url : 'http://' + url;          
	return (
        <>        
        <Table.Cell title={url}><WebIcon name={url}><a href={link} rel="noopener noreferrer" target="_blank">{url}</a></WebIcon></Table.Cell>
        <Table.Cell title={title} className="trunc">{title}</Table.Cell>          
        <Table.Cell>{moment.utc(time, 'X').format("D MMM, h:mm a")}</Table.Cell>       
        <Table.Cell>
            <Dropdown className="actions-dropdown" icon="ellipsis vertical" direction="left" text=''>       
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onAddClick(url, 'block')}>
                        <div><Icon className="block-icon" name="minus circle"/>Block site<ToolTip text='Prevent access to this website.'/></div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onAddClick(url, 'limit')}>
                        <div><Icon className="limit-icon" name="time"/>Limit site<ToolTip text='Apply time restrictions for this website, guided by your settings from the Website Limits tab'/></div>
                    </Dropdown.Item>                    
                </Dropdown.Menu>
            </Dropdown>
        </Table.Cell>            
        </>
	)
}

const collumns = [			
    { title : 'URL', style : {width : ""}},
    { title : 'Title', style : {width : ""}},              
    { title : 'Time', style : {width : "140px"}},	
    { title : ' ', className : 'th-fixed-50'},               		
];                

const LastWebsites = ({uid, currentComputer, onAddWebsite}) => {
    const [report, setReport] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(moment());     

    useEffect(() => {
        if (!currentComputer) return; 
        
        const reportRef = firebase.database().ref(`${uid}/${currentComputer}/reports/lastWebsites`); 
        reportRef.orderByChild('time').limitToLast(80).once('value',
            // success
            (snapshot) => {    
                
                const report = Object.values(snapshot.val() || {});                                      
                report.sort((a, b) => {
                    return b.time - a.time
                });
                
                setReport(report);                  
                reportRef.set(snapshot.val());
            },
            // error
            (error) => {
                console.log(error);            
            });
                         
        }, [uid, currentComputer, lastUpdate]);  

    const formatedLastUpdate = moment(lastUpdate).format('LTS');        
            
    return (
        <>   
            <h2 style={{marginBottom : 0, marginLeft: 20, marginTop : 10}}>
                    <span className='date-box' style={{width: '100%', textAlign : 'right'}}>                                        
                        <Icon title="Refresh" name='refresh' className='date-change' style={{fontSize : '0.8em', marginLeft : 20}} onClick={() => setLastUpdate(moment())}/>  
                        <span style={{fontSize: '0.9em'}}>{formatedLastUpdate}</span>                              
                    </span>           
            </h2>	                     
            <div style={{padding: 10, minWidth : 700}}>	                    				    
                <ReportTable unstackable collumns={collumns} list={report}>
                    { (index, item) => <WebsiteItem index={index} item={item} onAddClick={onAddWebsite} />}
                </ReportTable>
            </div>                
        </>
        )
}

export default withUID(withWebFilter(LastWebsites));
