import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import withUID from './../withUID';
import ClearLogsButton from '../UI/ClearLogsButton';
import ComputerTimeTable from './tables/ComputerTimeTable';

const ComputerTime = ({uid, currentComputer}) => {
    const [computerTime, setComputerTime] = useState([]);
    const [timeRef, setTimeRef] = useState(null);

    const handleClearLogs = () => {
        if (timeRef) timeRef.remove();
        const commandsRef = firebase.database().ref(`${uid}/${currentComputer}/commands`);
        const command = { clearComputerTime: firebase.database.ServerValue.TIMESTAMP };
        commandsRef.update(command);
        setComputerTime([]);
    }

    const getComputerTime = () => {
        const timeRef = firebase.database().ref(`${uid}/${currentComputer}/reports/time`);
        setTimeRef(timeRef);
        timeRef.on('value',
            // success
            (snapshot) => {
                const raw = snapshot.val() || {};                
                const computerTime = Object.keys(raw).reverse().reduce((out, date) => {
                    Object.keys(raw[date]).forEach((user) => {
                        out.push({
                            date,
                            user,
                            ...raw[date][user]
                        })
                    })
                    return out;
                }, []);

                setComputerTime(computerTime);
            },
            // error
            (error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (!currentComputer) return;
        getComputerTime();

        return () => {
            if (timeRef) timeRef.off();
        }
    }, []);

    return (
        <>
            <ClearLogsButton onClick={handleClearLogs} />
            <h2>Computer Time</h2>
            <div style={{ padding: 10 }}>
                <ComputerTimeTable chart={computerTime} />
            </div>
        </>
    )
}

export default withUID(ComputerTime);