import React from 'react';
import domains from '../UI/domains';
import {extractHostname} from '../../utils';

const WebIcon = (props) => {    
    const {name} = props;
    const domain = extractHostname(name);             
    const iconIndex = domain ? domains.findIndex(item => item === domain) + 1 : 0;           
    return <div className='webicon trunc' style={{backgroundImage : `url(/icons/a${iconIndex}.png`}}>{props.children}</div>   
}

export default WebIcon;