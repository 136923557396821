export const SET_USER = 'set_user';
export const CLEAR_USER = 'clear_user';

export const SET_COMPUTERS = 'set_computers';
export const SET_CURRENT_COMPUTER = 'set_current_computer';

export const SET_LICENSE_STATUS = 'set_license_status';

export const COPY_SETTINGS = 'copy_settings';

export const SET_WEEK = 'SET_WEEK';
export const SET_DATE = 'SET_DATE';
