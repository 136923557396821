const browsers = [
  "Brave",
  "Brave Browser",
  "Firefox",
  "Microsoft Edge",
  "Opera",
  "Opera Internet Browser",
  "Safari",
  "Safari Browser",
  "Google Chrome",
  "Internet Explorer",
];  

const unsupportedBrowsers = [
  "Norton Secure Browser",
  "DuckDuckGo",
  "CCleaner Browser",
  "WaveBrowser",
  "360 Browser",
  "Avant Browser",
  "Avast Secure Browser",
  "AVG Secure Browser",
  "Baidu",
  "Baidu Browser",
  "Bitty Browser",
  "Chromium",
  "CometBird",
  "Crazy Browser",
  "CrazyBrowser",
  "Cyberfox",
  "Deepnet Explorer",
  "Dolphin",
  "Dolphin Browser",
  "Epic Privacy Browser",
  "Exsoul Web Browser",
  "Fast Browser",
  "Flock",
  "Gate Browser",
  "Ghost Browser",
  "Ghostzilla",
  "GreenBrowser",
  "Maxthon",
  "Min Browser",
  "MSN Explorer",
  "Netscape Navigator",
  "Orkut Cute",
  "Pale Moon",
  "PirateBrowser",
  "Puffin Browser",
  "QQ browser",
  "Safari",
  "Safari Browser",
  "ShenzBrowser",
  "Slim Browser",
  "SlimBrowser",
  "Sogou Explorer",
  "SRWare Iron Browser",
  "TheWorld Browser",
  "Tor Browser",
  "UC Browser",
  "Vivaldi",
  "Waterfox",
  "WebNavigator Browser",
  "ZenBrowser"
];
  
const messengers = [
  "Discord Messenger",
  "Facebook Messenger",
  "Google Hangouts",
  "Skype",
  "Slack",
  "Spike",
  "Telegram Desktop",
  "Viber",
  "Voxer",
  "whatsapp",
  "Unigram",
  "Discord",
  "WhatsApp",
  "BetterDiscord",
  "ClientForWhatsappNew",
  "Thunderbird",
  "Microsoft Outlook",
  "Messenger",
  "Skype for Business",
  "Webex",
  "Client Application for Adobe Connect",
  "Signal",
  "GoToMeeting",
  "LINE",
  "Imo Messenger",
  "WhatsApp (Outdated)",
  "Zalo",
  "WeChat",
  "discord"
];
  
const games = [  
  "brawlhallagame",
  "minecraft.windows",
  "Minecraft 3D animation software",
  "DayZ Launcher",
  "prison architect64",
  "ravenfield",
  "mysingingmonsters",
  "Sid Meier's Civilization V",
  "NVIDIA GeForce NOW",
  "flightsimulator",
  "stormworks64",
  "PortalWars",
  "kingdomcome",
  "Death Stranding",
  "THE FINALS",
  "FIFA 22",
  "Horizon Zero Dawn",
  "FalloutNV",
  "Starfield",
  "homescapes",
  "Dead by Daylight",
  "bh3",
  "henrystickmin",
  "MuMu Player",
  "Godot Engine",
  "Gameloop",
  "Destiny 2",
  "Skyrim",
  "totallyaccuratebattlesimulator",
  "tabletop simulator",
  "DayZ",
  "lotroclient64",
  "sonsoftheforest",
  "7daystodie",
  "ShooterGame",
  "Europa Universalis IV",
  "risk of rain 2",
  "BannerlordLauncher",
  "Space Engineers",
  "golf with your friends",
  "hillclimbracing",
  "fortniteclient-win64-shipping",
  "dolphin",
  "cuphead",
  "Red Dead Redemption 2",
  "theforest",
  "asphalt8",
  "StarCraft II",
  "rimworldwin64",
  "Cyberpunk 2077",
  "Warframe",
  "deadcells",
  "isaac-ng",
  "forzahorizon5",
  "Stellaris",
  "robloxstudiobeta",
  "Gaijin Smart Launcher",
  "stumble guys",
  "Executable for Chess Game",
  "unturned",
  "Official Krunker.io Client",
  "portal2",
  "BeamNG.drive",
  "crab game",
  "phasmophobia",
  "bloonstd6",
  "Minecraft: Education Edition",
  "valheim",
  "recroom",
  "bg3",
  "Rainbow Six",
  "GOG Galaxy",
  "candycrushsaga",
  "gacha club",
  "Grand Theft Auto IV",
  "gta_sa",
  "teardown",
  "dragoncity",
  "ck3",
  "subnautica",
  "No Man's Sky",
  "Steam Client Bootstrapper",
  "FiveM",
  "CurseForge",
  "gang beasts",
  "left4dead2",
  "FPS Chess",
  "among us",
  "projectzomboid64",
  "vrchat",
  "Origin",
  "Star Rail",
  "Sid Meier's Civilization VI (DX11)",
  "Farlight 84",
  "TrackmaniaUplay",
  "HOI4",
  "asphalt9_w10_x64_rtl",
  "DeadByDaylight",
  "Stardew Valley",
  "raft",
  "Deep Rock Galactic",
  "Unity Editor",
  "rustclient",
  "xboxpcapp",
  "Garry's Mod",
  "yanderesimulator",
  "geometrydash",
  "Unity Hub",
  "aimlab_tb",
  "fallguys_client_game",
  "Terraria",
  "people playground",
  "lethal company",
  "Overwatch Application",
  "game",
  "War Thunder Client",
  "csgo",
  "osu!",
  "Grand Theft Auto V",
  "Rocket League",
  "Paradox Launcher",
  "Minecraft Education",
  "Apex Legends",
  "Ubisoft Connect",
  "hl2",
  "dota2",
  "dnplayer",
  "League of Legends (TM) Client",
  "Rockstar Games",
  "League of Legends",
  "genshinimpact",
  "Genshin Impact",
  "VALORANT",
  "Minecraft Launcher",
  "BattleвЂ¤net",
  "Minecraft",
  "EA",
  "solitaire",
  "RobloxStudio",
  "Xbox App",
  "BlueStacks",
  "robloxplayerbeta",
  "Fortnite",
  "minecraft",
  "windows10universal",
  "EpicGamesLauncher",
  "Steam",
  "Roblox",
  "Roblox Game Client"
];
  
const socialMedia = [
  'Facebook',
  'Instagram',
  'Twitter',
  'Pinterest',
  'TikTok',
  'Reddit',
  'LinkedIn',
  'Tumblr',
  'VKontakte',
  'Hootsuite',
  'Buffer',
  'TweetDeck',
  'Flickr',
  'Myspace',
  'LiveJournal',
  'XING',
  'Gab',
  'MeWe',
  'Minds',
  'Ello',
  'Diaspora',
  'Friendica',
  'Hubzilla',
  'Sociall',
  'Vero'
];
  
const education = [
  "Microsoft Whiteboard",
  "ScratchJr",
  "JW Library",
  "Autodesk Revit",
  "ClassIn",
  "Acellus",
  "anki",
  "zoom",
  "e-Sword",
  "Scratch 3",
  "Zoom Meetings"
];
  
const entertainment = [
  "SteamVR",
  "OculusClient",
  "iTunes",
  "music.ui",
  "Spotify",
  "DisneyPlus",
  "Winamp"
];
  
const creative = [
  "Adobe Audition 2022",
  "Adobe Premiere Pro 2020",
  "musescore4",
  "Figma",
  "VEGAS Pro",
  "Camtasia 2020",
  "Pro Tools Application",
  "ultimaker-cura",
  "firealpaca",
  "Adobe Premiere Pro 2022",
  "CLIP STUDIO PAINT",
  "medibangpaintpro",
  "Adobe Premiere Pro 2024",
  "Adobe Photoshop 2024",
  "ScreenRec",
  "Camtasia 2023",
  "Adobe After Effects 2022",
  "Adobe Illustrator 2020",
  "paint.net",
  "Adobe After Effects 2023",
  "Autodesk Access User Interface",
  "Adobe Photoshop 2021",
  "Adobe Illustrator 2022",
  "Bandicam - bdcam",
  "Adobe Photoshop CS6",
  "ibisPaint",
  "DaVinci Resolve",
  "Adobe Photoshop 2022",
  "Adobe Premiere Pro 2023",
  "Adobe Photoshop 2023",
  "Canva",
  "GNU Image Manipulation Program",
  "Adobe Photoshop 2020",
  "Blender",
  "FL Studio",
  "capcut",
  "Creative Cloud",
  "Clipchamp",
  "OBS Studio",
  "paintstudio.view",
  "mspaint",
  "Paint"
];
  
  const development = [
  "RStudio",
  "Android Studio",
  "studio",
  "Autodesk Fusion 360",
  "Embarcadero RAD Studio for Windows",
  "Dev-C++ IDE",
  "Atom",
  "DB Browser for SQLite",
  "SSMS 19",
  "eclipse",
  "IntelliJ IDEA",
  "Code::Blocks IDE",
  "WebStorm",
  "AutoCAD Application",
  "Notepad++ : a free (GNU) source code editor",
  "JetBrains Toolbox",
  "Visual Studio Code - Insiders",
  "Sublime Text",
  "Unity playback engine.",
  "Zulu Platform x64 Architecture",
  "Microsoft Visual Studio 2022",
  "PyCharm",
  "Python",
  "Visual Studio Code",
  "Java(TM) Platform SE binary",
  "OpenJDK Platform binary"
];
  
const news = [
  "microsoft.msn.news"
];
  
const productivity = [
  "OpenOffice 4.1.13",
  "TextMaker",
  "Pixillion Image Converter",
  "Pomatez",
  "HandBrake",
  "RescueTime",
  "OpenOffice 4.1.14",
  "pomofocus",
  "SamsungNotes",
  "FocusMe",
  "Microsoft Power BI Desktop",
  "tomato",
  "SumatraPDF",
  "LibreOffice",
  "Canon Quick Menu",
  "Wondershare PDFelement",
  "Epson Scan 2 Launcher",
  "FocusToDo",
  "Greenshot",
  "RemNote",
  "Canon IJ Scan Utility",
  "Foxit Reader 10.1",
  "Citrix Workspace",
  "HPScan",
  "Windows Calculator",
  "Adobe Reader and Acrobat Manager",
  "pdfeditor",
  "iPrint&Scan",
  "WPS Office",
  "teams",
  "Microsoft Publisher",
  "Cisco Webex Service",
  "Flow",
  "Epson Software Updater",
  "Lightshot",
  "Microsoft Access",
  "WinZip",
  "Notion",
  "Microsoft Office Excel",
  "WPS Writer",
  "Microsoft Office Word",
  "Microsoft To Do",
  "Notepad++",
  "OneNote",
  "TeamViewer",
  "PrinterControl4",
  "calculator",
  "Microsoft OneNote",
  "Windows Wordpad Application",
  "Microsoft.Notes",
  "AnyDesk",
  "Remote Desktop Connection",
  "Snipping Tool",
  "snippingtool",
  "screensketch",
  "notepad",
  "WinRAR archiver",
  "Microsoft PowerPoint",
  "Microsoft Teams",
  "Microsoft Teams (work or school)",
  "Notepad",
  "Microsoft Excel",
  "Calculator",
  "Microsoft Word"
];
  
  const video = [
  "Sky Go",
  "IPTVSmartersPro",
  "YouTube",
  "BS.Player",
  "stremio",
  "primevideo",
  "PotPlayer",
  "MPC-HC (x64)",
  "4K Video Downloader",
  "KMPlayer 64X",
  "Windows Media Player",
  "video.ui",
  "VLC media player",
  "Microsoft.Media.Player"
];
  
const aliases = ["Browsers", "Unsupported Browsers", "Communication", "Games", "Social Media", "Entertainment", "Education", "Creative", "Development", "News", "Productivity", "Video"];
const lists = [browsers, unsupportedBrowsers, messengers, games, socialMedia, entertainment, education, creative, development, news, productivity, video]

  export const appToCategory = (name = '') => {
    let category = 'Other';    
    name = name.replace(/\.exe$/, ''); // remove .exe (FacebookApp.exe -> FacebookApp)
   // name = name.replace(/App$/, "");   // remove App  (FacebookApp -> Facebook)
    
    lists.forEach((list, index) => {
        if (list.includes(name)) {
          category = aliases[index]; // Use the corresponding category alias
        }
      });      
    return category;
  }
  

  