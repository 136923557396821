import React from 'react';
import {Popup, Icon} from 'semantic-ui-react';

const ToolTip = ({text, pinned}) => (
    <Popup
        {...(pinned ? { on: 'click' } : {})}
        content={text}
        trigger={<Icon name='info circle' color='blue' style={{fontSize: "1rem", marginLeft : 10}}/>}
    />)

export default ToolTip;