import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: "AIzaSyABYhC6zEUDXEV2q_l42EZjDSrFJhOqRiY",
  authDomain: "family-shield-a516b.firebaseapp.com",
  databaseURL: "https://family-shield-a516b.firebaseio.com",
  projectId: "family-shield-a516b",
  storageBucket: "family-shield-a516b.appspot.com",
  messagingSenderId: "416499948308",
  appId: "1:416499948308:web:770f5db562d95d92b08001",
  measurementId: "G-S7Q7VQFRJM"
};

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;