import React from 'react';
import { Container, Header, Grid, Icon, Segment, Button} from 'semantic-ui-react';
import {ages} from './profiles';

const WizardSelectAge = ({age, onSelectAge, onClickBack, onClickNext}) => {
    const selectedAgeRange = ages.find(item => item.age === age);
    return (
    <Container text id='wizard'>
    <Header as='h2'>HT Family Shield: Getting Started - Step 3/4</Header>
    <p className='main-text'>Choose your child's age to set appropriate protection levels:</p>        
    <Grid style={{marginLeft : 10}}>
        <Grid.Row relaxed>
            {ages.map(item => (
                <Grid.Column className="column-age" key={item.age} width={3}>
                    <Segment onClick={() => onSelectAge(item.age)} className={`age ${age===item.age ? 'selected' : ''}`}>                      
                        <h4 style={{fontSize : '1em'}}>{item.label}</h4>
                    </Segment>
                </Grid.Column>   
            ))}
        </Grid.Row>        
    </Grid>  

    {selectedAgeRange && (
        <>
        <Header as='h3'>{selectedAgeRange.title}</Header>
        <ul>
            {selectedAgeRange.features.map((feature, index) => (
                <li className='feature-item' key={index}>
                    <Icon name={feature.icon} color='blue' style={{marginRight: 10}}/>{feature.label}
                </li>
            ))}
        </ul>
        </>
     )}        
 
    <p className="disclaimer">* Settings made during the setup wizard are not final and can be easily modified later.</p>
    <div style={{ textAlign: 'right', marginTop: '20px', marginBottom : '20px'}}> {/* Добавлен div для выравнивания кнопки вправо */}
        <Button color='blue' style={{marginRight : 20}} onClick={onClickBack}>Back</Button>           
        <Button color='blue' onClick={onClickNext}>Next</Button>
    </div>         
    </Container>
)}

export default WizardSelectAge;