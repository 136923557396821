import React from 'react';

const ToDoList = () => (    
    <div className='todolist'>
        <div className='todolist-container'>
            <div className='todolist-item'>
                <div className='todolist-number'>1</div>
                <h2>Sign up</h2>
                <p className='todolist-description'>Create your HT Family Shield account.</p>
            </div>
            <div className='todolist-item'>
                <div className='todolist-number'>2</div>
                <h2>Download and install</h2>
                <p className='todolist-description'>
                    <a href="https://downloads.ht-vector.com/family_shield_setup.exe" className='todolist-link'>
                        Download
                    </a> and install the <strong>HT Family Shield</strong> app on your child's computer or laptop to keep them safe online. After installation, log in to your account.
                </p>
            </div>
            <div className='todolist-item'>
                <div className='todolist-number'>3</div>                 
                <h2>Use your online dashboard</h2>
                <p className='todolist-description'>Access your dashboard to view reports, set rules, and block inappropriate content on your child's devices in real-time.</p>
            </div>
        </div>
    </div>
);

export default ToDoList;