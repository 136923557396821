import React from 'react';
import Computers from './Computers';
import {ComputerMenu, CollapsedComputerMenu} from './SidebarNavigation';
import Media from 'react-media';

const Sidebar = () => {      
    return (   
      <nav className="main-sidebar">
          <Media queries={{ small: { maxWidth: "767.98px" } }}>
          {matches =>
            matches.small ? (
              /* collapsed sidebar */
              <div className="nav flex-column " style={{marginTop: "45px", display: "block"}}>      
                <Computers size="mini"/>
                <CollapsedComputerMenu/>
              </div>        
            ) : ( 
                /* default sidebar */             
              <div className="sidebar-sticky">
                <div className="nav flex-column " style={{display: "block"}}>      
                  <Computers/>
                  <ComputerMenu/>                  
                </div>
              </div>
              )
          }            
          </Media>   
      </nav>  
    )
  }
  
export default Sidebar;
