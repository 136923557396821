import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Container, Header, Button} from 'semantic-ui-react';

const WizardIntro = ({hasSettings, onTryLater, onClickNext}) => (
    <Container text id='wizard'>
    <Header as='h2'>HT Family Shield: Getting Started - Step 1/4</Header>
    <p className='main-text'>Would you like to use this wizard to set predefined rules for your child's online safety?</p>
    <div style={{height : '330px', padding : 20, paddingTop : 50, }}>
    {hasSettings&&
    <Alert severity="warning">
        <AlertTitle>Important</AlertTitle>
        <p>Proceeding with this wizard will overwrite your current settings with new predefined ones. <strong>Previous settings will be lost.</strong></p>
        <p>Ensure you wish to proceed.</p>
      </Alert>}
    </div> 
    <div style={{ textAlign: 'right', marginBottom : '20px'}}>
        <Button  style={{marginRight : 20}} onClick={onTryLater}>I'll Try Later</Button>         
        <Button color='blue' onClick={onClickNext}>Next</Button>
    </div>         
    </Container>
)

    export default WizardIntro;
