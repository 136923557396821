import React from 'react';
import {Table} from 'semantic-ui-react';

const ReportTable = (props) => {
	if (!props.list || props.list.length === 0) return <h3 className="no-data text-center">NO DATA</h3>;	
    const {list = [], collumns = []} = props;
    
	const head = collumns.map((collumn) =>  <Table.HeaderCell key={collumn.title} style={collumn.style} className={collumn.className}>{collumn.title}</Table.HeaderCell>);

	const body = list.map((item, index) => { 
		const isBlocked = item.title && item.title.includes('[Blocked');
		const trClass = isBlocked ? 'red' : '';

		return(
			<Table.Row verticalAlign="middle" className={trClass} key={index}>
				{props.children(index + 1, item)}
			</Table.Row>)
		});

	return (						
			<Table verticalAlign='middle' striped unstackable={props.unstackable === true} style={{tableLayout : "fixed", width: "100%"}}>
				<Table.Header>
				<Table.Row>
					{head}
				</Table.Row>
				</Table.Header>
				<Table.Body>
					{body}
				</Table.Body>
			</Table>
		);		
}

export default ReportTable;